import { CompanyServiceCode } from "@homesusa/core";
import { useGetFormErrors } from "@homesusa/form";
import { Service } from "core/interfaces";
import { getServiceRules } from "modules/company/rules/service-subscription-rules";

export const useServiceFormHasErrors = (
  formState: Service
): (() => boolean) => {
  const { getFormErrorsProps } = useGetFormErrors();
  const hasError = (): boolean => {
    const errors = getFormErrorsProps(
      { ...formState },
      getServiceRules(formState.serviceCode === CompanyServiceCode.XMLImport)
    );
    return errors != null && errors.length > 0;
  };

  return hasError;
};
