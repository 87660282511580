export enum CompanyFormTab {
  General = "general",
  Mls = "mls",
  PhotographyServices = "photographyServices"
}

export const CompanyFormTabLabel = new Map<CompanyFormTab, string>([
  [CompanyFormTab.General, "General"],
  [CompanyFormTab.Mls, "MLS"],
  [CompanyFormTab.PhotographyServices, "Photography Services"]
]);
