import React from "react";
import { useParams } from "react-router-dom";

import { CorporationPanel } from "../components";
import { CorporationProvider } from "../providers";

function CorporationDetail(): JSX.Element {
  const params = useParams<{ id: string }>();

  if (params.id == null) {
    return <></>;
  }

  return (
    <CorporationProvider corporationId={params.id}>
      <CorporationPanel />
    </CorporationProvider>
  );
}

export default CorporationDetail;
