import React from "react";
import { Typography, Grid } from "@mui/material";

import {
  FormContext,
  FormStyled,
  Select,
  Input,
  NumberInput,
  flattenRules
} from "@homesusa/form";
import { AuthCompanyContext } from "@homesusa/auth";
import { FormCreateBtnGroupStyled } from "core/styles";
import { useCreateCompany } from "../hooks";
import { CompanyFormContextProps } from "../contexts";
import { CompanyFormProvider } from "../providers";
import { quickCreateRules } from "../rules";
import { SubmitButton } from "@homesusa/layout";

function FormCreate(): JSX.Element {
  const { otherProps } = React.useContext<CompanyFormContextProps>(FormContext);
  const { currentMarket } = React.useContext(AuthCompanyContext);

  const onSave = useCreateCompany();

  return (
    <FormStyled>
      <form data-testid="company-form">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">General</Typography>
            <hr />
          </Grid>
          <Grid item xs={6}>
            <Input name="name" />
          </Grid>
          <Grid item xs={6}>
            <Select name="type" />
          </Grid>
          <Grid item xs={6}>
            <Select name="market" defaultValue={currentMarket} />
          </Grid>
          <Grid item xs={6}>
            <Select
              name="corporationId"
              options={otherProps ? otherProps.corporations : {}}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Address</Typography>
            <hr />
          </Grid>
          <Grid item xs={6}>
            <NumberInput name="addressInfo.streetNum" />
          </Grid>
          <Grid item xs={6}>
            <Input name="addressInfo.streetName" />
          </Grid>
          <Grid item xs={6}>
            <Input name="addressInfo.city" />
          </Grid>
          <Grid item xs={6}>
            <Select name="addressInfo.state" />
          </Grid>
          <Grid item xs={6}>
            <NumberInput name="addressInfo.zip" />
          </Grid>
        </Grid>
      </form>
      <FormCreateBtnGroupStyled>
        <SubmitButton onClick={onSave}>SAVE</SubmitButton>
      </FormCreateBtnGroupStyled>
    </FormStyled>
  );
}

function CompanyFormCreate(): JSX.Element {
  const { currentCompany } = React.useContext(AuthCompanyContext);
  return (
    <CompanyFormProvider
      initialData={
        currentCompany
          ? Object.assign({
              corporationId: currentCompany.corporationId,
              corporationName: currentCompany.corporationName
            })
          : undefined
      }
      rules={flattenRules(quickCreateRules)}
    >
      <FormCreate />
    </CompanyFormProvider>
  );
}

export default CompanyFormCreate;
