import React from "react";
import { Column } from "react-table";
import { Delete, Info } from "@mui/icons-material";
import { Format } from "@homesusa/core";
import { Phones } from "core/interfaces";
import { IconButtonStyled } from "core/styles";
import { GridElementContext } from "../contexts";
import {
  MlsPhoneTypeForm,
  MlsPhoneTypeFormLabel,
  MlsPhoneTypeFormTooltip
} from "core/enums";
import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";

const CellTooltip = ({ title }: { title?: string }): JSX.Element => {
  if (!title) {
    return <></>;
  }
  return (
    <Tooltip title={title} sx={{ fontSize: "14px" }}>
      <Info color="disabled" />
    </Tooltip>
  );
};
type columnPhones = Array<Column<Phones>>;
export const useContactMlsPhonesColumns = (readOnly: boolean): columnPhones => {
  const { onDelete } = React.useContext(GridElementContext);
  const columns: Array<Column<Phones>> = [
    {
      Header: "Phone",
      accessor: (data: Phones): string => Format.PhoneNumber(data.number)
    },
    {
      Header: "Phone Type",
      accessor: (data: Phones): JSX.Element => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "0 8px" }}>
          {MlsPhoneTypeFormLabel.get(
            MlsPhoneTypeForm[data.phoneType as MlsPhoneTypeForm]
          ) ?? data.phoneType}
          <CellTooltip
            title={MlsPhoneTypeFormTooltip.get(
              data.phoneType as MlsPhoneTypeForm
            )}
          />
        </Box>
      )
    },
    {
      Header: "Actions",
      disableSortBy: true,
      accessor: (data: Phones): Phones => data,
      Cell: function Actions({ row }: { row: { index: number } }): JSX.Element {
        return (
          <div>
            <IconButtonStyled
              title="Delete"
              onClick={(e): void => {
                e.stopPropagation();
                onDelete(row.index);
              }}
              disabled={readOnly}
            >
              <Delete />
            </IconButtonStyled>
          </div>
        );
      }
    }
  ];

  return columns;
};
