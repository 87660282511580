import React from "react";
import { LinearProgress } from "@mui/material";

import { FormProvider } from "@homesusa/form";
import { Service } from "core/interfaces";
import { getService } from "core/services/companies/company-service.service";
import { FormErrors } from "../form-errors.component";
import { CompanyServicesFormFields } from "./form-fields.component";
import { CompanyServicesFormButtons } from "./form-buttons.component";

export const CompanyServicesForm = ({
  companyId,
  serviceId
}: {
  companyId: string;
  serviceId?: string;
}): JSX.Element => {
  const [service, setService] = React.useState<Service>();

  const refreshService = React.useCallback(async (): Promise<void> => {
    setService(undefined);
    const serviceResponse = !!serviceId
      ? await getService(companyId, serviceId)
      : ({} as Service);
    setService(serviceResponse);
  }, [companyId, serviceId]);

  React.useEffect(() => {
    refreshService();
  }, [refreshService]);

  if (service === undefined) {
    return <LinearProgress />;
  }

  return (
    <FormProvider<Service> initialFormState={service}>
      <FormErrors />
      <CompanyServicesFormFields />
      <CompanyServicesFormButtons />
    </FormProvider>
  );
};
