import React from "react";
import { useParams } from "react-router-dom";

import { CompanyPanel } from "../components";
import { CompanyProvider } from "../providers";

function CompanyDetail(): JSX.Element {
  const params = useParams<{ id: string }>();

  if (params.id == null) {
    return <></>;
  }

  return (
    <CompanyProvider companyId={params.id}>
      <CompanyPanel />
    </CompanyProvider>
  );
}

export default CompanyDetail;
