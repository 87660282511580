import axios from "axios";

import { GridResponse } from "@homesusa/grid-table";
import {
  Employee,
  User,
  CreateEmployeeDataForm,
  EmployeeDataForm,
  RequestFilter
} from "core/interfaces";
import { RoleEmployee } from "@homesusa/core";

const apiUrl = `${process.env.REACT_APP_API_URL}api/`;

const addEmployeeToCompany = async (
  companyEmployees: CreateEmployeeDataForm[],
  companyId?: string
): Promise<void> => {
  return axios.post(
    `${apiUrl}companies/${companyId}/employees`,
    companyEmployees
  );
};

const getCompanyEmployees = async (
  id: string,
  params?: RequestFilter & {
    roles?: RoleEmployee[];
  }
): Promise<GridResponse<Employee>> => {
  const response = await axios.get<GridResponse<Employee>>(
    `${apiUrl}companies/${id}/employees`,
    { params }
  );

  return response.data;
};

const getPossibleEmployees = async (id: string): Promise<User[]> => {
  return axios
    .get<User[]>(`${apiUrl}companies/${id}/possible-employees`)
    .then((response) => response.data);
};

const deleteEmployees = async (
  employees: string[],
  companyId?: string
): Promise<void> => {
  return axios
    .delete(`${apiUrl}companies/${companyId}/employees`, {
      data: { employees }
    })
    .then(() => {
      return Promise.resolve();
    });
};

const activateEmployees = async (
  employees: string[],
  companyId?: string
): Promise<void> => {
  return axios
    .patch(`${apiUrl}companies/${companyId}/employees/activate`, {
      employees
    })
    .then(() => {
      return Promise.resolve();
    });
};

const updateEmployee = async (
  companyId: string,
  employee: EmployeeDataForm
): Promise<void> => {
  const { id, ...formattedEmployee } = employee;
  return axios.put(
    `${apiUrl}companies/${companyId}/employees/${id}`,
    formattedEmployee
  );
};

const getEmployee = async (
  companyId: string,
  id: string
): Promise<EmployeeDataForm> => {
  return axios
    .get<EmployeeDataForm>(
      apiUrl + "companies/" + companyId + "/employees/" + id
    )
    .then((response) => response.data);
};

export {
  activateEmployees,
  deleteEmployees,
  getPossibleEmployees,
  addEmployeeToCompany,
  getCompanyEmployees,
  updateEmployee,
  getEmployee
};
