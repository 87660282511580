import axios from "axios";

import { GridResponse } from "@homesusa/grid-table";
import { RequestFilter, Service } from "core/interfaces";

const apiUrl = `${process.env.REACT_APP_API_URL}api/`;

const createService = async (
  companyId: string,
  service: Service
): Promise<Service> => {
  const response = await axios.post(
    `${apiUrl}companies/${companyId}/service-subscriptions`,
    service
  );
  return response.data as Service;
};

const getService = async (companyId: string, id: string): Promise<Service> => {
  const response = await axios.get(
    apiUrl + "companies/" + companyId + "/service-subscriptions/" + id
  );
  return response.data;
};

const updateService = async (
  companyId: string,
  service: Service
): Promise<Service> => {
  const { id, ...formattedService } = service;
  const response = await axios.put(
    `${apiUrl}companies/${companyId}/service-subscriptions/${id}`,
    formattedService
  );
  return response.data;
};

const deleteService = async (
  serviceId: string,
  companyId: string
): Promise<void> =>
  axios
    .delete(
      `${apiUrl}companies/${companyId}/service-subscriptions/${serviceId}`
    )
    .then(() => {
      return Promise.resolve();
    });

const getActiveServices = async (
  id: string,
  params?: RequestFilter & {
    serviceCode?: string[];
  }
): Promise<GridResponse<Service>> => {
  const { serviceCode, ...restParams } = params ?? {};
  const response = await axios.get<GridResponse<Service>>(
    apiUrl + "companies/" + id + "/service-subscriptions",
    {
      params: {
        ...restParams,
        serviceCode
      }
    }
  );

  return response.data;
};

export {
  getActiveServices,
  createService,
  getService,
  updateService,
  deleteService
};
