import React from "react";
import { BrowserRouter } from "react-router-dom";

import { AuthAppProvider } from "@homesusa/auth";
import { useYbug } from "@homesusa/core";
import {
  appStateDefault,
  AppType,
  AppProvider,
  ThemeProvider
} from "@homesusa/layout";
import Routes from "./routes.component";
import { getSidebarItems } from "../constants";

export function App(): JSX.Element {
  const sidebarItems = getSidebarItems();
  useYbug();

  return (
    <BrowserRouter>
      <ThemeProvider>
        <AuthAppProvider>
          <AppProvider
            initialState={{
              ...appStateDefault,
              currentApp: AppType.CompanyManagement,
              layout: {
                ...appStateDefault.layout,
                sidebar: {
                  ...appStateDefault.layout.sidebar,
                  selected: Object.keys(sidebarItems).shift() ?? "",
                  items: sidebarItems
                }
              }
            }}
          >
            <Routes />
          </AppProvider>
        </AuthAppProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
