import React, { Fragment } from "react";

import { tableContainerStyle } from "@homesusa/core";
import { AccordionBox, SummaryField } from "@homesusa/record";
import { Phones } from "core/interfaces";
import { PhoneTypeFormLabel, PhoneTypeForm } from "core/enums";
import { PhoneGrid } from "./phone-grid.component";
import { useGetPhonesSummary } from "../hooks";

type PhoneSummaryField = SummaryField<Phones>;

export function PhoneSummary(): JSX.Element {
  const phones: PhoneSummaryField[] = useGetPhonesSummary(
    Object.values(PhoneTypeForm)
  );

  if (phones.length === 0) {
    return <Fragment></Fragment>;
  }

  return (
    <AccordionBox title="Phones" sx={tableContainerStyle}>
      <PhoneGrid phones={phones} phoneTypeMap={PhoneTypeFormLabel} />
    </AccordionBox>
  );
}
