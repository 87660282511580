import React from "react";

import { GridTable, GridProvider, GridToolbar } from "@homesusa/grid-table";
import { CorporationContext } from "../contexts";
import { useCompanyGridColumns } from "core/hooks";

function CorporationCompaniesGrid(): JSX.Element {
  const { companies, getCompanies } = React.useContext(CorporationContext);

  React.useEffect(() => {
    getCompanies({});
  }, [getCompanies]);

  const columns = useCompanyGridColumns();

  if (!companies) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <GridProvider
        options={{ columns, data: companies?.data }}
        totalRows={companies?.total}
        fetchData={getCompanies}
      >
        <GridToolbar>
          <GridToolbar.SearchFilter />
        </GridToolbar>
        <GridTable />
      </GridProvider>
    </div>
  );
}

export default CorporationCompaniesGrid;
