import React from "react";

import { FormContext } from "@homesusa/form";
import { EmployeeFormContextProps } from "modules/company/contexts/employee-form";

export const useEmployeeFormHasErrors = (): boolean => {
  const { formState, formErrors } =
    React.useContext<EmployeeFormContextProps>(FormContext);

  const isNullOrEmpty = (value: unknown): boolean =>
    value == null || value === "";

  const hasErrors = React.useMemo(
    (): boolean =>
      isNullOrEmpty(formState.roleName) ||
      isNullOrEmpty(formState.userId) ||
      isNullOrEmpty(formState.title) ||
      (formErrors != null && Object.keys(formErrors).length > 0),
    [formState.roleName, formState.userId, formState.title, formErrors]
  );

  return hasErrors;
};
