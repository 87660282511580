export enum EmailEntityTypeForm {
  Sale = "sale",
  Lease = "lease",
  Lot = "lot"
}

export const EmailEntityTypeFormLabel = new Map<EmailEntityTypeForm, string>([
  [EmailEntityTypeForm.Sale, "Sale"],
  [EmailEntityTypeForm.Lease, "Lease"],
  [EmailEntityTypeForm.Lot, "Lot"]
]);
