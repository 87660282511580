import { formContextReducer } from "@homesusa/form";
import { CompanyDataForm as Company } from "core/interfaces";
import { CompanyFormActions } from "./company-form-actions.type";
import { companyFormEmailLeadsReducer } from "./company-form-email-leads.reducer";
import { companyFormPhonesReducer } from "./company-form-phones.reducer";

export const companyFormReducer = (
  state: Company,
  action: CompanyFormActions
): Company => {
  switch (action.type) {
    case "AddPhone":
    case "RemovePhone":
    case "UpdatePhone": {
      return companyFormPhonesReducer(state, action);
    }
    case "AddEmailLead":
    case "RemoveEmailLead":
    case "UpdateEmailLead": {
      return companyFormEmailLeadsReducer(state, action);
    }
    default: {
      return formContextReducer(state, action);
    }
  }
};
