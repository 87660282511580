// components/company-form.tsx
import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip
} from "@mui/material";
import { Info } from "@mui/icons-material";

import { FormContext, Switch } from "@homesusa/form";
import { useRefresh } from "@homesusa/core";
import { CompanyContext } from "modules/company/contexts";

export function LockListingsSwitch(): JSX.Element {
  const {
    company: {
      settingInfo: { lockListingsForSpecDeckOnboarding }
    }
  } = React.useContext(CompanyContext);
  const { formDispatch } = React.useContext(FormContext);
  const [showModal, setShowModal] = React.useState(false);
  const [hideSwitch, refreshSwitch] = useRefresh();
  const revertChange = (): void => {
    if (formDispatch) {
      formDispatch({
        type: "FormChangeByInputName",
        inputName: "settingInfo.lockListingsForSpecDeckOnboarding",
        payload: false
      });
    }
  };

  const onCancel = (): void => {
    revertChange();
    setShowModal(false);
    refreshSwitch();
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {!hideSwitch && (
        <Switch
          label="Lock all listings for SpecDeck Onboarding"
          name="settingInfo.lockListingsForSpecDeckOnboarding"
          disabled={lockListingsForSpecDeckOnboarding}
          onChange={(value): void => {
            if (!lockListingsForSpecDeckOnboarding && value) {
              setShowModal(true);
            }
          }}
        />
      )}
      <Tooltip title="By turning this button ON you are locking all Builder listings in Specdeck, Company Admin users will have to unlock them. Once they do it, the listings will be permanently locked in Quicklister.">
        <Info color="disabled" />
      </Tooltip>
      <Dialog
        open={showModal}
        onClose={onCancel}
        aria-labelledby="dialog__title"
      >
        <DialogTitle id="dialog__title">
          Lock all listings for SpecDeck Onboarding
        </DialogTitle>
        <DialogContent>
          <p>
            By turning this button ON you are locking all Builder listings in
            Specdeck, Company Admin users will have to unlock them. Once they do
            it, the listings will be permanently locked in Quicklister.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={(): void => setShowModal(false)}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
