import React from "react";

import { isValidGuid } from "@homesusa/core";
import { AppContext } from "@homesusa/layout";
import { FormContext, useGetFormErrors } from "@homesusa/form";
import { updateCompany } from "core/services/companies/company.service";
import { CompanyContext, CompanyFormContextProps } from "../contexts";

export const useUpdateCompany = (): (() => Promise<void>) => {
  const { getCompany } = React.useContext(CompanyContext);
  const { formState } = React.useContext<CompanyFormContextProps>(FormContext);
  const { addAlert } = React.useContext(AppContext);

  const { hasErrors } = useGetFormErrors();

  const onSave = async (): Promise<void> => {
    if (hasErrors()) {
      return Promise.resolve();
    }

    if (!isValidGuid(formState.id)) {
      return Promise.resolve();
    }

    return updateCompany(formState).then(() => {
      addAlert({
        message: "The request was successfully updated",
        variant: "success"
      });
      getCompany();
    });
  };

  return onSave;
};
