import React from "react";
import { Paper, Typography, styled, Box } from "@mui/material";
import { SxProps } from "@mui/system";

export const PaperStyled = styled(Paper)(() => ({
  padding: "16px 24px",
  boxSizing: "border-box",
  height: "100%",
  "& .MuiGrid-direction-xs-column > .MuiGrid-item": {
    width: "100%"
  }
}));

export function SectionBox({
  children,
  title,
  sx
}: {
  children: React.ReactNode;
  title?: string;
  sx?: SxProps;
}): JSX.Element {
  return (
    <PaperStyled sx={sx} elevation={4}>
      <Box>
        {title && (
          <Typography
            variant="subtitle2"
            sx={{
              pb: 1.5
            }}
            color="primary"
          >
            {title}
          </Typography>
        )}
        {children}
      </Box>
    </PaperStyled>
  );
}
