import React from "react";

import { AppContext } from "@homesusa/layout";
import { deleteEmployees } from "core/services/companies/company-employee.service";
import { CompanyContext } from "../contexts";

export const useDeleteEmployee = (): ((
  employeeId: string
) => Promise<void>) => {
  const { company, getEmployees } = React.useContext(CompanyContext);
  const { addAlert } = React.useContext(AppContext);

  const onDeleteById = (employeeId: string): Promise<void> =>
    deleteEmployees([employeeId], company.id).then(() => {
      addAlert({
        message: "The employee was successfully deleted",
        variant: "success"
      });
      getEmployees({});

      return Promise.resolve();
    });

  return onDeleteById;
};
