import React from "react";

import { AppContext } from "@homesusa/layout";
import { CreateEmployeeDataForm } from "core/interfaces";
import { addEmployeeToCompany } from "core/services/companies/company-employee.service";
import { CompanyContext } from "../contexts";

export const useAddEmployees = (): ((
  employees: CreateEmployeeDataForm[]
) => Promise<void>) => {
  const { company, getEmployees } = React.useContext(CompanyContext);
  const { addAlert } = React.useContext(AppContext);

  const addEmployee = (employees: CreateEmployeeDataForm[]): Promise<void> =>
    addEmployeeToCompany(employees, company.id).then(() => {
      addAlert({
        message: "The Employee was successfully added",
        variant: "success"
      });
      getEmployees({});
      return Promise.resolve();
    });

  return addEmployee;
};
