export const contactInfoRules = {
  emailRealtorsContact: {
    label: "Email Realtors Contact"
  },
  primaryContactId: {
    label: "Primary Contact"
  },
  accountingContactId: {
    label: "Accounting Contact"
  }
};
