import React from "react";

import { GridResponse } from "@homesusa/grid-table";
import {
  Company,
  ContextFetchData,
  CorporationDataForm
} from "core/interfaces";

export const CorporationContext = React.createContext<{
  corporation: CorporationDataForm;
  getCorporation: () => void;
  companies?: GridResponse<Company>;
  getCompanies: (params: ContextFetchData) => Promise<void>;
}>(Object.assign({}));
