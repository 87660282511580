import React from "react";
import { Box, Fade } from "@mui/material";
import { SxProps } from "@mui/system";

interface AnimatedProps {
  show?: boolean;
  time?: number;
  sx?: SxProps;
  children: React.ReactNode;
}
export const AnimatedContainer = (props: AnimatedProps): JSX.Element => {
  const { show = true, time = 500, sx, children } = props;
  return (
    <Fade in={show} timeout={time}>
      <Box sx={sx}>{children}</Box>
    </Fade>
  );
};
