import React from "react";

import { MarketCodeLabel } from "@homesusa/core";
import { FormToolbar } from "@homesusa/form";
import { CompanySavedChangesContext } from "modules/company-history/contexts";
import { CompanyContext } from "modules/company/contexts";
import { CompanySummary } from "./company-summary.component";
import { ShowNavigationRequestButton } from "./toolbar-buttons/show-navigation-requests-button.component";

export function CompanySummaryDetails(): JSX.Element {
  const { showSummaryView } = React.useContext(CompanySavedChangesContext);
  const { company } = React.useContext(CompanyContext);
  const title = React.useMemo(
    () => `${company.name} - ${MarketCodeLabel.get(company.market)}`,
    [company.name, company.market]
  );
  return (
    <>
      <FormToolbar>
        <FormToolbar.Left title={title}></FormToolbar.Left>
        <FormToolbar.Right>
          <ShowNavigationRequestButton />
        </FormToolbar.Right>
      </FormToolbar>
      {showSummaryView ? <CompanySummary /> : <></>}
    </>
  );
}
