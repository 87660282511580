import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules, zipCodeRule } from "@homesusa/form";
import { StateType, StateTypeLabel } from "core/enums";

export const mlsEntityRules: FormRules = {
  name: {
    label: "Name",
    required: true
  },
  streetNum: {
    label: "Street Number"
  },
  streetName: {
    label: "Street Name"
  },
  city: {
    label: "City"
  },
  state: {
    label: "State",
    options: getOptionsFromEnumAndMap(Object.values(StateType), StateTypeLabel)
  },
  zip: {
    ...zipCodeRule
  },
  url: {
    label: "URL",
    regex: `^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$`,
    regexMessage: "Please provide a valid URL."
  },
  mlsInformation: {
    label: "MLS Information",
    maxLength: 6000
  }
};
