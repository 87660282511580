import React from "react";
import { Grid, LinearProgress } from "@mui/material";

import {
  FormStyled,
  Select,
  FormContext,
  Input,
  Autocomplete
} from "@homesusa/form";
import { RoleEmployee, RoleEmployeeLabel } from "@homesusa/core";
import { EmployeeFormContextProps } from "modules/company/contexts/employee-form";

enum FormMode {
  Create = "Create",
  Update = "Update"
}

export function EmployeeForm({
  formMode = FormMode.Create
}: {
  formMode?: keyof typeof FormMode;
}): JSX.Element {
  const { formState, otherProps } =
    React.useContext<EmployeeFormContextProps>(FormContext);

  if (otherProps == null) {
    return <LinearProgress />;
  }

  return (
    <FormStyled>
      <form data-testid="company-employee-form">
        <Grid container sx={{ px: 7 }} spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              label="User"
              name="userId"
              required
              options={otherProps.availableUsers}
              disabled={formMode == FormMode.Update}
            />
          </Grid>
          <Grid item xs={12}>
            <Input label="Title" name="title" required />
          </Grid>
          <Grid item xs={12}>
            <Select
              label="Role"
              name="roleName"
              required
              removeBlankOption
              defaultValue={formState.roleName ?? RoleEmployee.Readonly}
              options={Object.values(RoleEmployee).reduce(
                (a, key) => ({
                  ...a,
                  [key]: RoleEmployeeLabel.get(key)
                }),
                {}
              )}
            />
          </Grid>
        </Grid>
      </form>
    </FormStyled>
  );
}
