import React from "react";

import { isValidGuid } from "@homesusa/core";
import { AppContext } from "@homesusa/layout";
import { activateCompany } from "core/services/companies/company.service";
import { useNavigate } from "react-router-dom";

export const useActivateCompany = (): ((id: string) => void) => {
  const navigate = useNavigate();
  const { addAlert } = React.useContext(AppContext);

  const onActivate = (id: string): void => {
    if (!isValidGuid(id)) {
      return;
    }

    activateCompany(id).then(async () => {
      addAlert({
        message: "The company was successfully restored",
        variant: "success"
      });
      navigate("/");
    });
  };

  return onActivate;
};
