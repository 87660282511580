import React, { Fragment } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box
} from "@mui/material";
import { Delete, Save, SettingsBackupRestore } from "@mui/icons-material";

import { FormContext, FormMode } from "@homesusa/form";
import { SubmitButton } from "@homesusa/layout";
import { PersistentRightModalContext } from "@homesusa/core";
import { EmployeeFormContextProps } from "modules/company/contexts/employee-form";
import {
  useActivateEmployees,
  useDeleteEmployee,
  useUpdateEmployee
} from "modules/company/hooks";
import { useEmployeeFormHasErrors } from "modules/company/hooks/form";

export function EmployeeActionsGroup(): JSX.Element {
  const { setShowRightModal } = React.useContext(PersistentRightModalContext);
  const { formState, formMode } =
    React.useContext<EmployeeFormContextProps>(FormContext);
  const hasErrors = useEmployeeFormHasErrors();

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showActivateModal, setShowActivateModal] = React.useState(false);
  const onDelete = useDeleteEmployee();
  const onSave = useUpdateEmployee();
  const onActivate = useActivateEmployees();

  if (formMode === FormMode.ReadOnly) {
    return <></>;
  }

  return (
    <Fragment>
      <Box
        sx={{ my: 2, display: "flex", columnGap: 2, justifyContent: "center" }}
      >
        {formState.userId && !formState.isDeleted && (
          <SubmitButton
            onClick={(): Promise<void> =>
              onSave(formState).then(() => setShowRightModal(false))
            }
            buttonProps={{
              startIcon: <Save />,
              disabled: hasErrors
            }}
          >
            Save
          </SubmitButton>
        )}
        {formState.isDeleted ? (
          <Button
            onClick={(): void => setShowActivateModal(true)}
            title="Restore"
            startIcon={<SettingsBackupRestore />}
          >
            Restore
          </Button>
        ) : (
          <Button
            onClick={(): void => setShowDeleteModal(true)}
            title="Delete"
            startIcon={<Delete />}
            className="danger"
          >
            Delete
          </Button>
        )}
      </Box>

      <Dialog
        open={showDeleteModal}
        onClose={(): void => setShowDeleteModal(false)}
      >
        <DialogTitle>Delete Employee</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this employee?</p>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            onClick={(): Promise<void> =>
              onDelete(formState.id).then(() => {
                setShowDeleteModal(false);
                setShowRightModal(false);
              })
            }
          >
            Delete
          </SubmitButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showActivateModal}
        onClose={(): void => setShowDeleteModal(false)}
      >
        <DialogTitle>Restore Employee</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to Restore this employee?</p>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            onClick={(): Promise<void> =>
              onActivate([formState.id]).then(() => {
                setShowActivateModal(false);
                setShowRightModal(false);
              })
            }
          >
            Restore
          </SubmitButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
