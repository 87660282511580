import axios from "axios";
import { MarketCode } from "@homesusa/core";
import { MlsEntityDataForm } from "core/interfaces/mls-entity-data-form.interface";

const apiUrl = `${process.env.REACT_APP_API_URL}api/`;

const getMlsEntity = async (
  marketCode: MarketCode
): Promise<MlsEntityDataForm> => {
  const response = await axios.get(`${apiUrl}mls-entity/${marketCode}`);
  return response.data;
};

const upsertMlsEntity = async (
  marketCode: MarketCode,
  mlsEntity: MlsEntityDataForm
): Promise<MlsEntityDataForm> => {
  const response = await axios.put(
    `${apiUrl}mls-entity/${marketCode}`,
    mlsEntity
  );
  return response.data;
};

export { getMlsEntity, upsertMlsEntity };
