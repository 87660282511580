import { SxProps } from "@mui/system";
interface Styles {
  container: SxProps;
  sections: SxProps;
  box: SxProps;
  section: SxProps;
}
export const classes: Styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: "32px"
  },
  sections: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  box: { width: "48.5%" },
  section: {
    "& .MuiInputLabel-root": {
      maxWidth: "50%",
      "&.Mui-focused": {
        maxWidth: "100%"
      },
      "&.MuiFormLabel-filled": { maxWidth: "100%" }
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: "72%",
      right: 0,
      color: "primary.light",
      transition: ".2s",
      "&.Mui-focused": {
        bottom: "88%",
        fontSize: ".65rem"
      },
      "&.MuiFormHelperText-filled ": {
        bottom: "88%",
        fontSize: ".65rem"
      }
    }
  }
};
