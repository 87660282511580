import React from "react";

import { FormPanel } from "@homesusa/form";
import CorporationForm from "./corporation-form.component";
import CorporationToolbar from "./corporation-toolbar.component";
import { CorporationContext } from "../contexts";
import { CorporationFormProvider } from "../providers";
import CorporationCompaniesGrid from "./corporation-companies-grid.component";

function CorporationFormPanel(): JSX.Element {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const { corporation } = React.useContext(CorporationContext);

  if (!corporation) {
    return <p>Loading...</p>;
  }

  return (
    <CorporationFormProvider initialData={corporation}>
      <CorporationToolbar />
      <FormPanel
        tabs={["Information", "Companies"]}
        selectedTab={selectedTab}
        onChangeTab={(value: number): void => setSelectedTab(value)}
      >
        <FormPanel.TabContent index={0} selected={selectedTab}>
          <CorporationForm />
        </FormPanel.TabContent>
        <FormPanel.TabContent index={1} selected={selectedTab}>
          <CorporationCompaniesGrid />
        </FormPanel.TabContent>
      </FormPanel>
    </CorporationFormProvider>
  );
}

export default CorporationFormPanel;
