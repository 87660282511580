import React from "react";
import { Grid, Typography } from "@mui/material";

import { Input, NumberInput } from "@homesusa/form";
import { AnimatedContainer, SectionBox } from "core/components";

const BrokerComponent = (): JSX.Element => {
  return (
    <AnimatedContainer>
      <Typography sx={{ width: "100%", my: 3 }} variant="h2">
        Broker Information
      </Typography>

      <Grid container columnGap={3}>
        <Grid item xs={5}>
          <SectionBox title="Agent Information">
            <Grid container>
              <Grid item xs={6}>
                <Input label="Agent UID" name="brokerInfo.agentUID" />
              </Grid>
              <Grid item xs={6}>
                <Input label="Office UID" name="brokerInfo.officeUID" />
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  label="Licence Number"
                  name="brokerInfo.licenseNumber"
                />
              </Grid>
            </Grid>
          </SectionBox>
        </Grid>

        <Grid item xs={5}>
          <SectionBox title="Site Information">
            <Grid container>
              <Grid item xs={6}>
                <Input label="Site Username" name="brokerInfo.siteUsername" />
              </Grid>
              <Grid item xs={6}>
                <Input label="Site Password" name="brokerInfo.sitePassword" />
              </Grid>
              <Grid item xs={6}>
                <Input label="Site Login URL" name="brokerInfo.siteLoginURL" />
              </Grid>
            </Grid>
          </SectionBox>
        </Grid>
      </Grid>
    </AnimatedContainer>
  );
};

export default BrokerComponent;
