import React from "react";

import { AppContext } from "@homesusa/layout";
import { updateEmployee } from "core/services/companies/company-employee.service";
import { CompanyContext } from "../contexts";
import { EmployeeDataForm } from "core/interfaces";

export const useUpdateEmployee = (): ((
  formData: EmployeeDataForm
) => Promise<void>) => {
  const { company, getEmployees } = React.useContext(CompanyContext);
  const { addAlert } = React.useContext(AppContext);

  const onSave = (formData: EmployeeDataForm): Promise<void> =>
    updateEmployee(company.id, formData).then(() => {
      addAlert({
        message: "The request was successfully updated",
        variant: "success"
      });
      getEmployees({});
      return Promise.resolve();
    });

  return onSave;
};
