import React from "react";
import { Column } from "react-table";
import { Delete } from "@mui/icons-material";
import { EmailLeads } from "core/interfaces";
import { IconButtonStyled } from "core/styles";
import {
  EmailEntityTypeForm,
  EmailEntityTypeFormLabel,
  EmailPriorityForm,
  EmailPriorityFormLabel
} from "core/enums";
import { GridElementContext } from "../contexts";

type columnEmails = Array<Column<EmailLeads>>;
export const useContactEmailsColumns = (readOnly: boolean): columnEmails => {
  const { onDelete } = React.useContext(GridElementContext);

  const columns: Array<Column<EmailLeads>> = [
    {
      Header: "Email",
      accessor: (data: EmailLeads): string => data.email ?? ""
    },
    {
      Header: "Type",
      accessor: (data: EmailLeads): string =>
        EmailEntityTypeFormLabel.get(data.entityType as EmailEntityTypeForm) ??
        data.entityType
    },
    {
      Header: "Priority",
      accessor: (data: EmailLeads): string =>
        EmailPriorityFormLabel.get(data.emailPriority as EmailPriorityForm) ??
        data.emailPriority
    },
    {
      Header: "Actions",
      disableSortBy: true,
      accessor: (data: EmailLeads): EmailLeads => data,
      Cell: function Actions({ row }: { row: { index: number } }): JSX.Element {
        return (
          <div>
            <IconButtonStyled
              title="Delete"
              onClick={(e): void => {
                e.stopPropagation();
                onDelete(row.index);
              }}
              disabled={readOnly}
            >
              <Delete />
            </IconButtonStyled>
          </div>
        );
      }
    }
  ];

  return columns;
};
