import React from "react";

import { GridProvider, GridTable } from "@homesusa/grid-table";
import { SectionBox } from "core/components";

import {
  useContactPhonesColumns,
  useContactMlsPhonesColumns
} from "modules/company/hooks";
import { Phones } from "core/interfaces";
import { CompanyFormContextProps, GridElementContext } from "../contexts";
import { FormContext, FormMode } from "@homesusa/form";

export const ContactPhonesGrid = ({
  phones,
  gridType
}: {
  phones: Phones[];
  gridType: "general" | "mls";
}): JSX.Element => {
  const { formMode } = React.useContext<CompanyFormContextProps>(FormContext);
  const generalColumns = useContactPhonesColumns(
    formMode === FormMode.ReadOnly
  );
  const mlsColumns = useContactMlsPhonesColumns(formMode === FormMode.ReadOnly);
  const { setEdit } = React.useContext(GridElementContext);

  const columns = gridType === "mls" ? mlsColumns : generalColumns;
  return (
    <SectionBox>
      <GridProvider
        options={{
          columns,
          data: phones,
          manualPagination: false
        }}
        totalRows={phones?.length}
        pageSize={5}
      >
        <GridTable onRowClick={setEdit} />
      </GridProvider>
    </SectionBox>
  );
};
