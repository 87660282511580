import React from "react";
import { Typography, Grid } from "@mui/material";

import { FormStyled, Select, Input, NumberInput } from "@homesusa/form";
import { SubmitButton } from "@homesusa/layout";
import { useCreateCorporation } from "../hooks";
import { FormCreateBtnGroupStyled } from "core/styles";

function CorporationFormCreate(): JSX.Element {
  const onSave = useCreateCorporation();

  return (
    <FormStyled>
      <form data-testid="company-form">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">General</Typography>
            <hr />
          </Grid>
          <Grid item xs={6}>
            <Input name="name" />
          </Grid>
          <Grid item xs={6}>
            <Input label="Web URL" name="webUrl" />
          </Grid>
          <Grid item xs={6}>
            <Input name="domain" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Address</Typography>
            <hr />
          </Grid>
          <Grid item xs={6}>
            <NumberInput name="streetNum" />
          </Grid>
          <Grid item xs={6}>
            <Input name="streetName" />
          </Grid>
          <Grid item xs={6}>
            <Input name="city" />
          </Grid>
          <Grid item xs={6}>
            <Select name="state" />
          </Grid>
          <Grid item xs={6}>
            <NumberInput name="zip" />
          </Grid>
        </Grid>
      </form>
      <FormCreateBtnGroupStyled>
        <SubmitButton onClick={onSave}>SAVE</SubmitButton>
      </FormCreateBtnGroupStyled>
    </FormStyled>
  );
}

export default CorporationFormCreate;
