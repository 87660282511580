import React from "react";

import { AppContext } from "@homesusa/layout";
import { activateEmployees } from "core/services/companies/company-employee.service";
import { CompanyContext } from "../contexts";
import { EntityStatus } from "core/enums";

export const useActivateEmployees = (): ((
  employeeIds: string[]
) => Promise<void>) => {
  const { company, getEmployees } = React.useContext(CompanyContext);
  const { addAlert } = React.useContext(AppContext);
  const pluralMessage = "All employees has been successfully restored";
  const singularMessage = "The employee has been successfully restored";

  const onActivateById = (employeeIds: string[]): Promise<void> =>
    activateEmployees(employeeIds, company.id).then(() => {
      addAlert({
        message: employeeIds.length > 1 ? pluralMessage : singularMessage,
        variant: "success"
      });
      getEmployees({ status: [EntityStatus.Archived] });

      return Promise.resolve();
    });

  return onActivateById;
};
