import React from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "@homesusa/layout";
import { FormContext, useGetFormErrors } from "@homesusa/form";
import { createCorporation } from "core/services/corporations/corporation.service";
import { CorporationFormContextProps } from "../contexts/corporation-form";
import { CustomError } from "core/hooks/use-catch-entity-error";

export const useCreateCorporation = (): (() => Promise<void>) => {
  const navigate = useNavigate();
  const { formState } =
    React.useContext<CorporationFormContextProps>(FormContext);
  const { addAlert } = React.useContext(AppContext);
  const { hasErrors } = useGetFormErrors();

  const onSave = async (): Promise<void> => {
    if (hasErrors()) {
      return Promise.resolve();
    }

    await createCorporation(formState)
      .then((id) => {
        addAlert({
          message: "The corporation was successfully created",
          variant: "success"
        });
        navigate(`/corporations/${id}`);
      })
      .catch((customError: CustomError) => {
        addAlert({
          message: customError.response.data,
          variant: "error"
        });
      });
  };

  return onSave;
};
