import { contactInfoRules, photoRequestInfoRules } from "./company-sections";
import { getPhotographyServiceRules } from "./photography-service-rules";
import { quickCreateRules } from "./quick-create-rules";

const settingInfoRules = {
  allowPhotoRequestForIncompleteHomes: {
    label: "Allow Photo Request Creation for Incomplete Homes"
  },
  displayDeletedListingsWindow: {
    label: "Hide Deleted Homes to List NOW Window"
  },
  hideLeadManager: {
    label: "Hide Lead Manager Link"
  },
  showRealtorReports: {
    label: "Hide Realtor Reports Link"
  },
  stopXMLMediaSyncOfExistingListings: {
    label: "Stop XML Media Sync of Existing Listings"
  },
  onlyPullHomesToList: {
    label: "Make Scraper Pull Only New Homes to List"
  },
  allowNewListings: {
    label: "Prevent New Listing Creation"
  },
  showSellingAgentInternalReport: {
    label: "Show Selling Agent Internal Report"
  },
  stopCompletedHomesWithOutPhotosEmails: {
    label: "Stop Completed Homes w/o Photos Emails"
  },
  stopAlertEmails: {
    label: "Stop Alert Emails"
  },
  hideExpiredDate: {
    label: "Hide Expired Date Field"
  },
  lockListingsForSpecDeckOnboarding: {
    label: "Lock all listings for SpecDeck Onboarding"
  },
  preventSalesEmployeeSubmit: {
    label: "Prevent Sales Employee Submit"
  },
  ignoreRequestByCompletionDate: {
    label: "Ignore Request By Completion Date"
  },
  lockShowingInstructions: {
    label: "Lock Showing Instructions"
  },
  disableSelfAddSalesEmployeeCommunity: {
    label: "Disable self add SE to Community"
  }
};

const phoneLeadInfoRules = {
  isCentralizedForSale: {
    label: "Centralize Lead Phone for Sale"
  },
  centralizeLeadPhone: {
    label: "Centralize Phone Lead for Sale"
  },
  isCentralizedForLease: {
    label: "Centralize Lead Phone for Lease"
  },
  centralizeLeadPhoneForLease: {
    label: "Centralize Phone Lead for Lease"
  },
  isCentralizedForLot: {
    label: "Centralize lead phone for lot"
  },
  centralizeLeadPhoneForLot: {
    label: "Centralize Phone Lead for Lot"
  }
};
const emailLeadInfo = {
  lockedEmailLeads: {
    label: "Restrict Email Leads Modification"
  }
};

const brokerInfoRules = {
  licenseNumber: {
    label: "License Number"
  },
  siteUsername: {
    label: "Site Username"
  },
  sitePassword: {
    label: "Site Password"
  },
  siteLoginURL: {
    label: "Site Login URL"
  },
  agentUID: {
    label: "Agent UID"
  },
  officeUID: {
    label: "Office UID"
  }
};
const mlsInfoRules = {
  includeMls: {
    label: "Do NOT include MLS#, Builder, and Completion Date in Remarks"
  },
  blockSquareFootage: {
    label: "Block Square Footage Field"
  },
  remarksForCompletedHomes: {
    label: "Remark format for completed homes"
  },
  buyerAgentCommission: {
    label: "Buyer Agent Commission"
  },
  buyerAgentCommissionType: {
    label: "Buyer Agent Commission Type"
  }
};
export const companyDetailsRules = {
  ...quickCreateRules,
  ...settingInfoRules,
  ...phoneLeadInfoRules,
  ...brokerInfoRules,
  ...mlsInfoRules,
  ...emailLeadInfo,
  webUrl: {
    label: "Web URL",
    tooltip: "The URL must contain http(s)://",
    regexMessage: "Please provide a valid URL."
  },
  domain: {
    label: "Email Domain",
    tooltip: "Add an email domain such as abchomes.com",
    regex: `^(?![a-zA-Z0-9]*www\.|http:\/\/|https:\/\/|@)(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$`,
    regexMessage: "Please provide a valid Email Domain."
  },
  brokerId: {
    label: "Broker"
  },
  customerRef: {
    label: "Customer Reference"
  },
  violationWarningEmail: {
    label: "Email"
  },
  companyType: {
    label: "Type"
  },
  contactInfo: contactInfoRules,
  photoRequestInfo: photoRequestInfoRules,
  photographyServiceInfo: getPhotographyServiceRules(false),
  rules: {
    label: "Company Rules"
  }
};
