import React, { Fragment } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from "@mui/material";
import { Delete } from "@mui/icons-material";

import { isValidGuid } from "@homesusa/core";
import { useDeleteCompany } from "../../hooks";
import { CompanyContext } from "../../contexts";

export function DeleteCompanyButton(): JSX.Element {
  const { company } = React.useContext(CompanyContext);

  const [showModal, setShowModal] = React.useState(false);
  const handleCloseModal = (): void => setShowModal(false);
  const handleShowModal = (): void => setShowModal(true);

  const onDelete = useDeleteCompany();

  const isValidCompanyId = React.useMemo((): boolean => {
    return !!company && isValidGuid(company.id);
  }, [company]);

  return (
    <Fragment>
      <Button
        onClick={handleShowModal}
        disabled={!isValidCompanyId}
        startIcon={<Delete />}
        className="danger"
      >
        Delete
      </Button>
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>Delete Company</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this company?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(): void => {
              handleCloseModal();
              onDelete();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
