import React from "react";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";

import {
  EmailInput,
  formContexErrorReducer,
  FormContext,
  FormContextActions,
  Select,
  formContextReducer,
  FormMode,
  FormRules,
  Switch
} from "@homesusa/form";
import { AppContext } from "@homesusa/layout";
import { SectionBox } from "core/components";
import {
  CompanyFormContextProps,
  GridElementContext
} from "modules/company/contexts";
import { useLocalOptions } from "modules/company/hooks";
import { EmailLeads } from "core/interfaces";

export const ContactEmailForm = ({
  initialData,
  onSuccess,
  onSave: propsOnSave
}: {
  initialData?: EmailLeads;
  onSave: (state: EmailLeads) => void;
  onSuccess: () => void;
}): JSX.Element => {
  const { addAlert } = React.useContext(AppContext);
  const {
    formState: { emailLeads, emailLeadInfo },
    formDispatch: dispatch,
    formMode
  } = React.useContext<CompanyFormContextProps>(FormContext);

  const [formState, formDispatch] = React.useReducer<
    (state: EmailLeads, action: FormContextActions<EmailLeads>) => EmailLeads
  >(formContextReducer, initialData ?? Object.assign({}));
  // Form errors
  const [formErrors, formErrorsDispatch] = React.useReducer(
    formContexErrorReducer,
    Object.assign({})
  );

  const { isEdit } = React.useContext(GridElementContext);
  const { emailPriorityOptions, emailTypeOptions } = useLocalOptions();
  const disableButton = React.useMemo(
    (): boolean =>
      formState.email &&
      formState.emailPriority &&
      formState.entityType &&
      Object.keys(formErrors ?? {}).length === 0
        ? false
        : true,
    [formState, formErrors]
  );

  const onSave = (): void => {
    if (isEdit || isUnique(formState)) {
      propsOnSave(formState);
      formDispatch({
        type: "OverrideState",
        payload: Object.assign({})
      });
      formErrorsDispatch({ type: "Clean" });
      onSuccess();
    } else {
      addAlert({
        message: "Only one email per type and priority is allowed",
        variant: "error"
      });
    }
  };

  const compareLeads = (a: EmailLeads, b: EmailLeads): boolean => {
    return a.entityType === b.entityType && a.emailPriority === b.emailPriority;
  };

  const isUnique = (newEmail: EmailLeads): boolean => {
    return !emailLeads.some((email) => compareLeads(email, newEmail));
  };

  const handleChangeLockedEmailLeads = (value: boolean): void => {
    if (dispatch) {
      dispatch({
        type: "FormChangeByInputName",
        inputName: "emailLeadInfo.lockedEmailLeads",
        payload: value
      });
    }
  };
  const formRules: FormRules = React.useMemo(
    () =>
      Object.assign({
        email: {
          label: "Email",
          required: true
        },
        entityType: {
          label: "Type",
          required: true,
          options: emailTypeOptions,
          fullWith: true
        },
        emailPriority: {
          label: "Priority",
          required: true,
          options: emailPriorityOptions,
          sort: false,
          fullWith: true
        }
      }),
    []
  );

  return (
    <FormContext.Provider
      value={{
        formState,
        formDispatch,
        formMode: formMode,
        formErrors,
        formRules,
        formErrorsDispatch,
        otherProps: {}
      }}
    >
      <SectionBox title="Emails" sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: 8,
            right: 10,
            display: "flex",
            alignItems: "center"
          }}
        >
          <Switch
            label="Restrict Email Leads Modification"
            name="emailLeadInfo.lockedEmailLeads"
            onChange={handleChangeLockedEmailLeads}
            defaultValue={emailLeadInfo.lockedEmailLeads}
          />
          <Tooltip title="If enabled, the possibility to modify the email leads in the community form will be removed.">
            <Info color="disabled" />
          </Tooltip>
        </Box>
        <Grid container>
          <Grid item xs={6}>
            <EmailInput name="email" />
          </Grid>
          <Grid item xs={3}>
            <Select name="entityType" disabled={isEdit} />
          </Grid>
          <Grid item xs={3}>
            <Select name="emailPriority" disabled={isEdit} />
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography variant="caption" color="initial">
            Fill in the requested fields and press the {isEdit ? "edit" : "add"}{" "}
          </Typography>
          <Button
            disabled={disableButton || formMode === FormMode.ReadOnly}
            onClick={onSave}
          >
            {isEdit ? "Edit" : "Add"}
          </Button>
        </Box>
      </SectionBox>
    </FormContext.Provider>
  );
};
