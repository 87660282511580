import React from "react";
import { LinearProgress } from "@mui/material";

import { FormProvider, FormContextActions } from "@homesusa/form";
import { CreateEmployeeDataForm } from "core/interfaces";
import { getPossibleEmployees } from "core/services/companies/company-employee.service";
import { OtherProps } from "modules/company/contexts/employee-form";
import { CompanyContext } from "../../contexts";
import { EmployeeForm } from "./employee-form.component";
import { CreateEmployeeButton } from "./create-employee-button.component";

export function NewEmployeeForm(): JSX.Element {
  const { company } = React.useContext(CompanyContext);
  const [users, setUsers] = React.useState<Record<string, string>>();

  React.useEffect(() => {
    getPossibleEmployees(company.id).then((data) => {
      setUsers(
        data.reduce(
          (a, item) => ({
            ...a,
            [item.id]: `${item.firstName} ${item.lastName} (${item.userName})`
          }),
          {}
        )
      );
    });
  }, [company.id]);

  if (users == null) {
    return <LinearProgress />;
  }

  return (
    <FormProvider<
      CreateEmployeeDataForm,
      FormContextActions<CreateEmployeeDataForm>,
      OtherProps
    >
      otherContextProps={{ availableUsers: users }}
    >
      <EmployeeForm />
      <CreateEmployeeButton />
    </FormProvider>
  );
}
