import axios from "axios";
import { GridResponse } from "@homesusa/grid-table";
import {
  Corporation,
  CorporationDataForm,
  Company,
  RequestFilter
} from "core/interfaces";

const apiUrl = `${process.env.REACT_APP_API_URL}api/`;

const getActiveCorporations = async (
  params?: RequestFilter
): Promise<GridResponse<Corporation>> => {
  const response = await axios.get<GridResponse<Corporation>>(
    `${apiUrl}corporations`,
    { params }
  );
  return response.data;
};

const getCorporation = async (id: string): Promise<CorporationDataForm> => {
  const response = await axios.get(`${apiUrl}corporations/${id}`);
  return response.data as CorporationDataForm;
};

const createCorporation = async (
  corporation: CorporationDataForm
): Promise<string> => {
  const response = await axios.post(`${apiUrl}corporations`, corporation);
  return response.data;
};

const updateCorporation = async (
  corporation: CorporationDataForm
): Promise<CorporationDataForm> => {
  const { id, ...companyDataForm } = corporation;
  const response = await axios.put(
    `${apiUrl}corporations/${id}`,
    companyDataForm
  );
  return response.data;
};

const deleteCorporation = async (id: string): Promise<void> =>
  axios.delete(`${apiUrl}corporations/${id}`).then(() => {
    return Promise.resolve();
  });

const getCorporationCompanies = async (
  id: string,
  params?: RequestFilter
): Promise<GridResponse<Company>> => {
  const response = await axios.get<GridResponse<Company>>(
    `${apiUrl}corporations/${id}/companies`,
    { params }
  );
  return response.data;
};

export {
  getActiveCorporations,
  getCorporation,
  createCorporation,
  updateCorporation,
  deleteCorporation,
  getCorporationCompanies
};
