import { isNullOrEmpty } from "@homesusa/form";
import { XMLBuilderNumber } from "core/interfaces";

export const xmlBuilderstoString = (
  builderNumbers?: XMLBuilderNumber[]
): string => {
  if (!builderNumbers) return "";
  return builderNumbers.map(({ number }) => number).join(";");
};

export const toXMLBuilderNumber = (
  builderNumbers?: string
): XMLBuilderNumber[] => {
  if (!builderNumbers?.length) return [];
  return builderNumbers.split(";").map((number) => ({ number }));
};

export const isArrayNullOrEmpty = (array: unknown[]): boolean =>
  isNullOrEmpty(array) || !array.length;
