import React, { Fragment } from "react";

import { NotificationsButton, FormContext } from "@homesusa/form";

export function FormErrors(): JSX.Element {
  const { formErrors } = React.useContext(FormContext);

  if (!formErrors) {
    return <Fragment></Fragment>;
  }

  return (
    <NotificationsButton totalErrors={Object.keys(formErrors).length}>
      <NotificationsButton.List
        onClickError={(): void => undefined}
        properties={formErrors}
      />
    </NotificationsButton>
  );
}
