import React, { Fragment } from "react";
import { Typography, Grid } from "@mui/material";

import {
  FormContext,
  FormStyled,
  Input,
  NumberInput,
  Select
} from "@homesusa/form";
import { AnimatedContainer, SectionBox } from "@homesusa/core";
import { useHasRole } from "@homesusa/auth";
import { ReverseProspectSection } from "./reverse-prospect-section.component";
import { CorporationFormContextProps } from "../contexts/corporation-form";

function CorporationForm(): JSX.Element {
  const { formState } =
    React.useContext<CorporationFormContextProps>(FormContext);
  const { isMlsAdministrator } = useHasRole();
  return (
    <AnimatedContainer>
      <FormStyled>
        <Typography sx={{ width: "100%", my: 3 }} variant="h2">
          Corporation Information
        </Typography>
        <Grid container rowGap={3}>
          <Grid item xs={12}>
            <Grid container columnGap={3} wrap="nowrap">
              <Grid item xs={6}>
                <SectionBox title="General">
                  <Grid container>
                    <Grid item xs={6}>
                      <Input name="name" />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Web URL"
                        name="webUrl"
                        tooltip="The URL must contain http(s)://"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input name="domain" />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="XML Corporation Number"
                        name="xmlCorporateBuilderNumber"
                        maxLength={30}
                      />
                    </Grid>
                  </Grid>
                </SectionBox>
              </Grid>
              <Grid item xs={6}>
                <SectionBox title="Address">
                  <Grid container>
                    <Grid item xs={6}>
                      <NumberInput name="streetNum" />
                    </Grid>
                    <Grid item xs={6}>
                      <Input name="streetName" />
                    </Grid>
                    <Grid item xs={6}>
                      <Input name="city" />
                    </Grid>
                    <Grid item xs={6}>
                      <Select name="state" />
                    </Grid>
                    <Grid item xs={6}>
                      <NumberInput name="zip" />
                    </Grid>
                  </Grid>
                </SectionBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isMlsAdministrator && formState.isHomesusaCorporation && (
          <Fragment>
            <Typography sx={{ width: "100%", my: 3 }} variant="h2">
              Reverse Prospect Information
            </Typography>
            <Grid container columnSpacing={5}>
              <Grid item xs={12}>
                <ReverseProspectSection />
              </Grid>
            </Grid>
          </Fragment>
        )}
      </FormStyled>
    </AnimatedContainer>
  );
}

export default CorporationForm;
