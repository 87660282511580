import React from "react";
import { useParams } from "react-router-dom";

import { EmployeeDetails } from "../components/employee";
import { CompanyProvider } from "../providers";

function EmployeeDetail(): JSX.Element {
  const params = useParams<{ id: string; employeeId: string }>();

  if (params.id == null || params.employeeId == null) {
    return <></>;
  }

  return (
    <CompanyProvider companyId={params.id}>
      <EmployeeDetails employeeId={params.employeeId} />
    </CompanyProvider>
  );
}

export default EmployeeDetail;
