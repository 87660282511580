import React from "react";
import { Grid } from "@mui/material";

import { flattenRules } from "@homesusa/form";
import { ContentBox, EntityRulesProvider } from "@homesusa/record";
import { useCompanySummaryMapping } from "modules/company-history/hooks";
import { companyDetailsRules } from "modules/company/rules";
import { PhoneSummary } from "./phone-summary.component";
import { EmailLeadSummary } from "./email-lead-summary.component";
import { BuilderNumberSummary } from "./builder-number-summary.component";
import { MlsPhoneSummary } from "./mls-phone-summary.component";

export function CompanySummary(): JSX.Element {
  const summaryInformation = useCompanySummaryMapping();
  return (
    <EntityRulesProvider rules={flattenRules(companyDetailsRules)}>
      <Grid container mt={1}>
        <Grid item xs={6} md={6}>
          <ContentBox
            title="Company Information"
            content={summaryInformation.general}
          />
          <BuilderNumberSummary />
          <PhoneSummary />
          <ContentBox
            title="Photo Request"
            content={summaryInformation.photoRequest}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <ContentBox title="MLS" content={summaryInformation.mls} />
          <MlsPhoneSummary />
          <EmailLeadSummary />
          <ContentBox
            title="Photography Services"
            content={summaryInformation.photography}
          />
        </Grid>
      </Grid>
    </EntityRulesProvider>
  );
}
