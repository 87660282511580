import React from "react";

import { AppContext } from "@homesusa/layout";
import { deleteEmployees } from "core/services/companies/company-employee.service";
import { CompanyContext } from "../contexts";

export const useDeleteEmployees = (): ((
  employeeIds: string[]
) => Promise<void>) => {
  const { company, getEmployees } = React.useContext(CompanyContext);
  const { addAlert } = React.useContext(AppContext);

  const onDelete = (employeeIds: string[]): Promise<void> =>
    deleteEmployees(employeeIds, company.id).then(() => {
      addAlert({
        message: "The employees were successfully deleted",
        variant: "success"
      });
      getEmployees({});

      return Promise.resolve();
    });

  return onDelete;
};
