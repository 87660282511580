import React from "react";

import { MarketCode, RoleEmployee } from "@homesusa/core";
import { FormMode, FormProvider, FormRules } from "@homesusa/form";
import { useHasRole } from "@homesusa/auth";
import { CompanyDataForm } from "core/interfaces";
import { CompanyTypeForm } from "core/enums";
import {
  companyFormReducer,
  CompanyFormActions
} from "../contexts/company-form";
import { getActiveCorporations } from "core/services/corporations/corporation.service";
import { CompanyFormOtherContextProps } from "../contexts/company-form/company-form-other-props.interface";

function CompanyFormProvider({
  initialData,
  children,
  rules
}: {
  children: React.ReactNode;
  initialData?: CompanyDataForm;
  rules: FormRules;
}): JSX.Element {
  const { isMlsAdministrator } = useHasRole();
  const [corporations, setCorporations] = React.useState<
    Record<string, string>
  >({});
  const { hasEmployeeRole } = useHasRole();
  const isReadOnly = React.useMemo(
    () => hasEmployeeRole([RoleEmployee.CompanyAdminReadonly]),
    [hasEmployeeRole]
  );

  React.useEffect((): void => {
    if (isMlsAdministrator) {
      getActiveCorporations().then(({ data }) => {
        setCorporations(
          data?.reduce((a, item) => ({ ...a, [item.id]: item.name }), {})
        );
      });
    } else if (
      !!initialData?.corporationId &&
      !Object.keys(corporations).includes(initialData.corporationId)
    ) {
      setCorporations({
        [initialData.corporationId]: initialData.corporationName
      });
    }
  }, [isMlsAdministrator]);
  return (
    <FormProvider<
      CompanyDataForm,
      CompanyFormActions,
      CompanyFormOtherContextProps
    >
      initialFormState={
        initialData ??
        Object.assign({
          type: CompanyTypeForm.Builder,
          market: MarketCode.Sabor
        })
      }
      initialFormRules={rules}
      formReducer={companyFormReducer}
      otherContextProps={{ corporations }}
      initialFormMode={isReadOnly ? FormMode.ReadOnly : FormMode.Update}
    >
      {children}
    </FormProvider>
  );
}

export { CompanyFormProvider };
