export enum StateType {
  AL = "al",
  AK = "ak",
  AR = "ar",
  AZ = "az",
  CA = "ca",
  CO = "co",
  CT = "ct",
  DC = "dc",
  DE = "de",
  FL = "fl",
  GA = "ga",
  HI = "hi",
  IA = "ia",
  ID = "id",
  IL = "il",
  IN = "in",
  KS = "ks",
  KY = "ky",
  LA = "la",
  MA = "ma",
  MD = "md",
  ME = "me",
  MI = "mi",
  MN = "mn",
  MO = "mo",
  MS = "ms",
  MT = "mt",
  NC = "nc",
  ND = "nd",
  NE = "ne",
  NH = "nh",
  NJ = "nj",
  NM = "nm",
  NV = "nv",
  NY = "ny",
  OK = "ok",
  OH = "oh",
  OR = "or",
  PA = "pa",
  RI = "ri",
  SC = "sc",
  SD = "sd",
  TN = "tn",
  TX = "tx",
  UT = "ut",
  VA = "va",
  VT = "vt",
  WA = "wa",
  WI = "wi",
  WV = "wv",
  WY = "wy"
}

export const StateTypeLabel = new Map<StateType, string>([
  [StateType.AL, "AL"],
  [StateType.AK, "AK"],
  [StateType.AR, "AR"],
  [StateType.AZ, "AZ"],
  [StateType.CA, "CA"],
  [StateType.CO, "CO"],
  [StateType.CT, "CT"],
  [StateType.DC, "DC"],
  [StateType.DE, "DE"],
  [StateType.FL, "FL"],
  [StateType.GA, "GA"],
  [StateType.HI, "HI"],
  [StateType.IA, "IA"],
  [StateType.ID, "ID"],
  [StateType.IL, "IL"],
  [StateType.IN, "IN"],
  [StateType.KS, "KS"],
  [StateType.KY, "KY"],
  [StateType.LA, "LA"],
  [StateType.MA, "MA"],
  [StateType.MD, "MD"],
  [StateType.ME, "ME"],
  [StateType.MI, "MI"],
  [StateType.MN, "MN"],
  [StateType.MO, "MO"],
  [StateType.MS, "MS"],
  [StateType.MT, "MT"],
  [StateType.NC, "NC"],
  [StateType.ND, "ND"],
  [StateType.NE, "NE"],
  [StateType.NH, "NH"],
  [StateType.NJ, "NJ"],
  [StateType.NM, "NM"],
  [StateType.NV, "NV"],
  [StateType.NY, "NY"],
  [StateType.OK, "OK"],
  [StateType.OH, "OH"],
  [StateType.OR, "OR"],
  [StateType.PA, "PA"],
  [StateType.RI, "RI"],
  [StateType.SC, "SC"],
  [StateType.SD, "SD"],
  [StateType.TN, "TN"],
  [StateType.TX, "TX"],
  [StateType.UT, "UT"],
  [StateType.VA, "VA"],
  [StateType.VT, "VT"],
  [StateType.WA, "WA"],
  [StateType.WI, "WI"],
  [StateType.WV, "WV"],
  [StateType.WY, "WY"]
]);
