import React from "react";
import { useParams } from "react-router-dom";
import { CompanyProvider } from "modules/company/providers";
import { CompanySavedChangesProvider } from "../contexts";
import { CompanySummaryDetails } from "../components";

function CompanySavedChangesDetails(): JSX.Element {
  const params = useParams<{ id: string; historyId: string }>();
  if (params.id == null || params.historyId == null) {
    return <></>;
  }
  return (
    <>
      <CompanyProvider companyId={params.id}>
        <CompanySavedChangesProvider companyHistoryId={params.historyId}>
          <CompanySummaryDetails />
        </CompanySavedChangesProvider>
      </CompanyProvider>
    </>
  );
}

export default CompanySavedChangesDetails;
