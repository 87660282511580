import React from "react";

import { SummaryField } from "@homesusa/record";
import { Phones } from "core/interfaces";
import { CompanySavedChangesContext } from "../contexts";

type PhoneSummaryField = SummaryField<Phones>;

function capitalizeFirstLetter(value: string): string {
  return value.charAt(0).toLowerCase() + value.slice(1);
}

export const useGetPhonesSummary = (
  phoneTypes: string[]
): PhoneSummaryField[] => {
  const { summary } = React.useContext(CompanySavedChangesContext);
  const phones = React.useMemo(() => {
    const section = summary.find((item) => item?.name === "Phones");
    const allPhones: PhoneSummaryField[] = section
      ? Object.assign(section).fields
      : [];
    return allPhones.filter((phone) =>
      phoneTypes.includes(capitalizeFirstLetter(phone.fieldName))
    );
  }, [summary]);

  return phones;
};
