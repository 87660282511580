import React from "react";
import { Box, Button, Grid, List, ListItem, ListItemText } from "@mui/material";
import { Autocomplete, FormContext } from "@homesusa/form";
import { SectionBox, EmergentWindow } from "core/components";
import { Employee } from "core/interfaces";
import {
  CompanyContext,
  CompanyFormContextProps
} from "modules/company/contexts";
import { Format } from "@homesusa/core";

export const ContactsComponent = (): JSX.Element => {
  const { employees } = React.useContext(CompanyContext);
  const { formState } = React.useContext<CompanyFormContextProps>(FormContext);
  const [selectedUser, setSelectedUser] = React.useState<Employee>();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const userOptions = employees?.data.reduce(
    (a, x) => ({ ...a, [x.userId]: `${x?.firstName} ${x.lastName}` }),
    {}
  );

  /* methods */

  const handleClick = (currentId: string): void => {
    const currentUser = employees?.data.find(
      ({ userId }) => userId === currentId
    );
    setSelectedUser(currentUser);
    setShowModal(true);
  };
  const handleClose = (): void => setShowModal(false);
  return (
    <Box>
      <SectionBox title="Contacts">
        <Grid container alignItems="center">
          <Grid item xs={9}>
            <Autocomplete
              label="Primary Contact"
              options={userOptions ?? {}}
              name="contactInfo.primaryContactId"
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={(): void =>
                handleClick(formState?.contactInfo.primaryContactId)
              }
              disabled={!formState?.contactInfo.primaryContactId}
            >
              View
            </Button>
          </Grid>
          <Grid item xs={9}>
            <Autocomplete
              label="Accounting Contact"
              options={userOptions ?? {}}
              name="contactInfo.accountingContactId"
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={(): void =>
                handleClick(formState?.contactInfo.accountingContactId)
              }
              disabled={!formState?.contactInfo.accountingContactId}
            >
              View
            </Button>
          </Grid>
        </Grid>
      </SectionBox>

      <EmergentWindow
        title="User Information"
        open={showModal}
        onClose={handleClose}
      >
        <List>
          <ListItem>
            <ListItemText
              primary="Full Name"
              secondary={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Email" secondary={selectedUser?.email} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="User Name"
              secondary={selectedUser?.userName}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Created On"
              secondary={Format.DateTime(selectedUser?.sysCreatedOn)}
            />
          </ListItem>
        </List>
      </EmergentWindow>
    </Box>
  );
};
