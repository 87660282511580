import React from "react";
import { FormContext, Input } from "@homesusa/form";
import Grid from "@mui/material/Grid";
import { XMLImportFormFieldsProps } from "core/interfaces/xml-import-form-fields-props.interface";
import {
  xmlBuilderstoString,
  toXMLBuilderNumber
} from "modules/company/utils/xml-builder.utils";
import { ServiceFormContext } from "modules/company/contexts";
import { XMLCorporationNumberFormFields } from "./xml-corporation-number.component";

export const XMLImportFormFields = (
  props: XMLImportFormFieldsProps
): JSX.Element => {
  const { formDispatch } = React.useContext<ServiceFormContext>(FormContext);
  const handleChange = (value: string | number | undefined): void => {
    if (!formDispatch) return;
    if (typeof value === "number") value = value.toString();
    const xmlBuilderNumbers = toXMLBuilderNumber(value);
    formDispatch({
      type: "FormChangeByInputName",
      inputName: "xmlBuilderNumbers",
      payload: xmlBuilderNumbers
    });
  };
  const builderNumber = xmlBuilderstoString(props?.xmlBuilderNumbers);
  return (
    <>
      <Grid item xs={12}>
        <Input
          label="XML Builder Numbers"
          name="xmlBuilderNumbers"
          id="xmlBuilderNumbers"
          defaultValue={builderNumber}
          onChange={handleChange}
          maxLength={100}
          required
          tooltip={
            "You can add more than one Builder Number by delimiting them with a semicolon ( ; )"
          }
        />
      </Grid>
      <XMLCorporationNumberFormFields
        xmlCorporateBuilderNumber={props?.xmlCorporateBuilderNumber}
        xmlBuilderNumbers={props.xmlBuilderNumbers}
      />
    </>
  );
};
