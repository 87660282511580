import {
  MarketCode,
  MarketCodeLabel,
  getOptionsFromEnumAndMap
} from "@homesusa/core";
import {
  CompanyTypeForm,
  CompanyTypeFormLabel,
  StateType,
  StateTypeLabel
} from "core/enums";
import { zipCodeRule } from "@homesusa/form";

export const addressInfoRules = {
  streetNum: {
    label: "Street Number",
    required: true,
    regex: "^[1-9]([-\\d]){0,7}$",
    regexMessage:
      "Street # must be a number without any decimal in the range of (1-9,999,999).",
    maxLength: 8,
    minLength: 1
  },
  streetName: {
    label: "Street Name",
    required: true
  },
  city: {
    label: "City",
    required: true
  },
  state: {
    label: "State",
    required: true,
    options: getOptionsFromEnumAndMap(Object.values(StateType), StateTypeLabel)
  },
  zip: {
    ...zipCodeRule,
    required: true
  }
};

export const quickCreateRules = {
  name: {
    label: "Name",
    required: true
  },
  type: {
    label: "Type",
    required: true,
    options: getOptionsFromEnumAndMap(
      Object.values(CompanyTypeForm),
      CompanyTypeFormLabel
    )
  },
  market: {
    label: "Market",
    disabled: true,
    options: getOptionsFromEnumAndMap(
      Object.values(MarketCode),
      MarketCodeLabel
    )
  },
  corporationId: {
    label: "Corporation",
    required: true
  },
  addressInfo: addressInfoRules
};
