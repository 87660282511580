import { IconButton, styled } from "@mui/material";

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[500],
  padding: "3px",
  "&:hover": {
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.light
    }
  }
}));
