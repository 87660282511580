import { formContextReducer } from "@homesusa/form";
import { CorporationDataForm as Corporation } from "core/interfaces";
import { CorporationFormActions } from "./corporation-form-actions.type";
import { CorporationReverseProspectFormReducer } from "./corporation-reverse-prospect-form.reducer";

export const companyFormReducer = (
  state: Corporation,
  action: CorporationFormActions
): Corporation => {
  switch (action.type) {
    case "AddReverseProspect":
    case "RemoveReverseProspect":
    case "UpdateReverseProspect": {
      return CorporationReverseProspectFormReducer(state, action);
    }
    default: {
      return formContextReducer(state, action);
    }
  }
};
