import { LinearProgress } from "@mui/material";
import React from "react";
import { CompanySavedChangesContext } from "./company-saved-changes.context";
import { CompanySavedChangesContextProps } from "./company-saved-changes-context-props.interface";
import { useGetCompanySummary } from "modules/company/hooks";
import { CompanyContext } from "modules/company/contexts";

export function CompanySavedChangesProvider({
  companyHistoryId,
  children
}: {
  companyHistoryId: string;
  children: React.ReactNode;
}): JSX.Element {
  const {
    company: { id },
    savedChanges
  } = React.useContext(CompanyContext);
  const { summary, getSummary } = useGetCompanySummary(companyHistoryId);
  const [showSummaryView, setShowSummaryView] = React.useState(true);

  React.useEffect(() => {
    getSummary();
  }, [getSummary]);

  if (!summary) {
    return <LinearProgress />;
  }

  return (
    <CompanySavedChangesContext.Provider
      value={
        {
          summary,
          showSummaryView,
          setShowSummaryView,
          savedChanges,
          companyHistoryId,
          companyId: id
        } as CompanySavedChangesContextProps
      }
    >
      {children}
    </CompanySavedChangesContext.Provider>
  );
}
