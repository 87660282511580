import React from "react";
import { Schedule, List, ManageAccounts, TableView } from "@mui/icons-material";
export enum CompanySectionForm {
  information = "information",
  employees = "employees",
  subscription = "subscriptions",
  historyChanges = "historyChanges"
}
export const CompanySectionFormLabel = new Map<CompanySectionForm, string>([
  [CompanySectionForm.information, "Information"],
  [CompanySectionForm.employees, "Employees"],
  [CompanySectionForm.subscription, "Service Subscriptions"],
  [CompanySectionForm.historyChanges, "Saved Changes"]
]);

export const CompanySectionFormIcon = new Map<CompanySectionForm, JSX.Element>([
  [
    CompanySectionForm.information,
    <List key={CompanySectionForm.information} />
  ],
  [
    CompanySectionForm.employees,
    <ManageAccounts key={CompanySectionForm.employees} />
  ],
  [
    CompanySectionForm.subscription,
    <TableView key={CompanySectionForm.subscription} />
  ],
  [
    CompanySectionForm.historyChanges,
    <Schedule key={CompanySectionForm.historyChanges} />
  ]
]);
