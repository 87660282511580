import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

export const EmergentWindow = ({
  open,
  onClose,
  children,
  title
}: {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
}): JSX.Element => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="info-dialog__title">
      <DialogTitle id="info-dialog__title" sx={{ fontSize: "1rem" }}>
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
