import React from "react";
import { Grid, LinearProgress, Typography } from "@mui/material";

import { FormProvider, FormContextActions, FormMode } from "@homesusa/form";
import { Format, RoleEmployee, isValidGuid } from "@homesusa/core";
import { EmployeeDataForm } from "core/interfaces";
import { getEmployee } from "core/services/companies/company-employee.service";
import { OtherProps } from "modules/company/contexts/employee-form";
import { CompanyContext } from "modules/company/contexts";
import { EmployeeForm } from "./employee-form.component";
import { EmployeeActionsGroup } from "./employee-actions-group.component";
import { useHasRole } from "@homesusa/auth";

export function EmployeeDetails({
  employeeId
}: {
  employeeId: string;
}): JSX.Element {
  const { company } = React.useContext(CompanyContext);
  const { hasEmployeeRole } = useHasRole();
  const isReadOnly = React.useMemo(
    () => hasEmployeeRole([RoleEmployee.CompanyAdminReadonly]),
    [hasEmployeeRole]
  );
  const [data, setFormData] = React.useState<EmployeeDataForm>();
  const availableUsers = React.useMemo(
    () => (data ? { [data.userId]: `${data.firstName} ${data.lastName}` } : {}),
    [data?.userId]
  );

  React.useEffect(() => {
    if (!isValidGuid(employeeId)) {
      return;
    }

    getEmployee(company.id, employeeId).then((data) => setFormData(data));
  }, [company.id, employeeId]);

  if (!data) {
    return <LinearProgress />;
  }

  return (
    <FormProvider<
      EmployeeDataForm,
      FormContextActions<EmployeeDataForm>,
      OtherProps
    >
      otherContextProps={{ availableUsers }}
      initialFormState={data}
      initialFormMode={isReadOnly ? FormMode.ReadOnly : FormMode.Update}
    >
      <Grid container spacing={1} sx={{ mb: 3 }}>
        <Grid item xs={6}>
          <Typography>
            <b>Created By: </b>
            {data.createdBy}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <b>Created On: </b>
            {Format.DateTime(data.sysCreatedOn)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <b>Modified By: </b>
            {data.modifiedBy}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <b>Modified On: </b>
            {Format.DateTime(data.sysModifiedOn)}
          </Typography>
        </Grid>
      </Grid>
      <EmployeeForm formMode="Update" />
      <EmployeeActionsGroup />
    </FormProvider>
  );
}
