import React, { Fragment } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";

import { SubmitButton } from "@homesusa/layout";
import { useDeleteService } from "modules/company/hooks";
import { IconButtonStyled } from "core/styles";

export const DeleteServiceButton = ({
  serviceId,
  isIconButton,
  onClose
}: {
  serviceId: string;
  isIconButton?: boolean;
  onClose?: () => void;
}): JSX.Element => {
  const [showModal, setShowModal] = React.useState(false);
  const { onDeleteById } = useDeleteService();

  if (!serviceId) {
    return <></>;
  }

  const handleCloseModal = (): void => {
    setShowModal(false);
    if (onClose) {
      onClose();
    }
  };

  const deleteService = async (): Promise<void> => {
    handleCloseModal();
    await onDeleteById(serviceId);
  };

  const deleteButtonProps: ButtonProps = {
    title: "Delete",
    onClick: (event): void => {
      event.stopPropagation();
      setShowModal(true);
    }
  };

  return (
    <Fragment>
      {!!isIconButton ? (
        <IconButtonStyled {...deleteButtonProps}>
          <DeleteIcon />
        </IconButtonStyled>
      ) : (
        <Button
          {...deleteButtonProps}
          startIcon={<DeleteIcon />}
          className="danger"
        >
          Delete
        </Button>
      )}
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>Delete Service</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Service?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          onClick={(event): void => {
            event.stopPropagation();
          }}
        >
          <SubmitButton onClick={deleteService}>Delete</SubmitButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
