import React, { Fragment } from "react";
import { Button } from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import { FormToolbar } from "@homesusa/form";
import { useDocumentTitle } from "@homesusa/layout";
import { SysInfo } from "core/interfaces/sys-info";
import { InfoIconButton } from "core/components";
import { useUpdateMlsEntity } from "../hooks";
import { MlsEntityContext } from "../contexts";

function MlsEntityToolbar(): JSX.Element {
  const { mlsEntity } = React.useContext(MlsEntityContext);
  useDocumentTitle(`Mls Entity - ${mlsEntity?.name}`);

  const onSave = useUpdateMlsEntity();
  const sysInfo = React.useMemo((): SysInfo => {
    const { sysCreatedOn, sysModifiedOn, createdBy, modifiedBy } = mlsEntity;
    return { sysCreatedOn, sysModifiedOn, createdBy, modifiedBy };
  }, [mlsEntity]);

  return (
    <Fragment>
      <FormToolbar>
        <FormToolbar.Left title={mlsEntity?.name} />
        <FormToolbar.Right>
          <Button
            variant="contained"
            color="primary"
            onClick={(): void => onSave()}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          <InfoIconButton information={sysInfo} />
        </FormToolbar.Right>
      </FormToolbar>
    </Fragment>
  );
}

export default MlsEntityToolbar;
