import { formContextReducer } from "@homesusa/form";
import { MlsEntityDataForm } from "core/interfaces/mls-entity-data-form.interface";
import { MlsEntityFormActions } from "./mls-entity-form-actions.type";

export const mlsEntityFormReducer = (
  state: MlsEntityDataForm,
  action: MlsEntityFormActions
): MlsEntityDataForm => {
  switch (action.type) {
    default: {
      return formContextReducer(state, action);
    }
  }
};
