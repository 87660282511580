import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";

import { Switch, NumberInput, FormContext, flattenRules } from "@homesusa/form";
import { useHasRole } from "@homesusa/auth";
import { AnimatedContainer, SectionBox } from "core/components";
import { CompanyFormContextProps } from "modules/company/contexts";
import { getPhotographyServiceRules } from "modules/company/rules";

export const PhotoServices = (): JSX.Element => {
  const {
    formState: { photographyServiceInfo },
    formErrorsDispatch,
    formRulesDispatch
  } = React.useContext<CompanyFormContextProps>(FormContext);
  const { isMlsAdministrator } = useHasRole();
  const readOnly = !isMlsAdministrator;

  return (
    <AnimatedContainer>
      <Typography sx={{ width: "100%", my: 3 }} variant="h2">
        Photography Services
      </Typography>
      <Switch
        label="Enable Photography Services"
        name="photographyServiceInfo.photographyServicesEnabled"
        disabled={readOnly}
        onChange={(value: boolean): void => {
          if (formErrorsDispatch && !value) {
            formErrorsDispatch({
              type: "Clean"
            });
          }
          if (formRulesDispatch) {
            formRulesDispatch({
              type: "UpdateRules",
              payload: flattenRules({
                photographyServiceInfo: getPhotographyServiceRules(
                  value ?? false
                )
              })
            });
          }
        }}
      />
      <>
        {photographyServiceInfo.photographyServicesEnabled && (
          <Grid container columnGap={3}>
            <Grid item xs={5}>
              <SectionBox title="Services Requested">
                <Stack spacing={2}>
                  <Grid container>
                    <Grid item xs={6}>
                      <NumberInput
                        name="photographyServiceInfo.stillHome"
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NumberInput
                        name="photographyServiceInfo.stillExteriorOnly"
                        disabled={readOnly}
                      />
                    </Grid>
                  </Grid>

                  <Switch
                    name="photographyServiceInfo.isFirstSetIncluded"
                    disabled={readOnly}
                  />

                  <Box>
                    <Typography sx={{ mb: 1 }}>Twilight</Typography>
                    <Grid container>
                      <Grid item xs={6}>
                        <NumberInput
                          name="photographyServiceInfo.twilight"
                          disabled={readOnly}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumberInput
                          name="photographyServiceInfo.virtualTour"
                          disabled={readOnly}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Typography sx={{ mb: 1 }}>Virtual Staging</Typography>
                    <Grid container>
                      <Grid item xs={4}>
                        <NumberInput
                          name="photographyServiceInfo.virtualStagingOneImage"
                          disabled={readOnly}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberInput
                          name="photographyServiceInfo.virtualStagingThreeImage"
                          disabled={readOnly}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumberInput
                          name="photographyServiceInfo.virtualStagingSixImage"
                          disabled={readOnly}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              </SectionBox>
            </Grid>
            <Grid item xs={5}>
              <Stack spacing={2}>
                <div>
                  <SectionBox title="Community Options">
                    <Grid container>
                      <Grid item xs={6}>
                        <NumberInput
                          tooltip="Four to five photos when ordered at the same time as the photography of a home in that community. Included are gate or entrance, pool, community center and playground with retouching of green grass and blue sky."
                          name="photographyServiceInfo.communityOptionOne"
                          disabled={readOnly}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumberInput
                          tooltip="Up to twenty-five photos with retouching of green grass and blue sky."
                          name="photographyServiceInfo.communityOptionTwo"
                          disabled={readOnly}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumberInput
                          tooltip="Up to twenty photos depending on the number of amenities with retouching for green grass, blue pool water and blue skies. If the recreational center has TVs, image are added to the TVs and fires are added in the fireplaces and fire pits."
                          name="photographyServiceInfo.communityOptionThree"
                          disabled={readOnly}
                        />
                      </Grid>
                    </Grid>
                  </SectionBox>
                </div>
                <div>
                  <SectionBox title="Trip Charge">
                    <Grid container>
                      <Grid item xs={6}>
                        <NumberInput
                          name="photographyServiceInfo.tripCharge"
                          disabled={readOnly}
                        />
                      </Grid>
                    </Grid>
                  </SectionBox>
                </div>
                <div>
                  <SectionBox title="Photoshop">
                    <Grid container>
                      <Grid item xs={6}>
                        <NumberInput
                          name="photographyServiceInfo.photoshopEditing"
                          disabled={readOnly}
                        />
                      </Grid>
                    </Grid>
                  </SectionBox>
                </div>
              </Stack>
            </Grid>
          </Grid>
        )}
      </>
    </AnimatedContainer>
  );
};
