import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { zipCodeRule } from "@homesusa/form";
import { StateType, StateTypeLabel } from "core/enums";

export const corporationRules: FormRules = {
  name: {
    label: "Name",
    required: true
  },
  domain: {
    label: "Email Domain",
    required: true,
    regex: `^(?![a-zA-Z0-9]*www\.|http:\/\/|https:\/\/|@)(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$`,
    regexMessage: "Please provide a valid domain."
  },
  streetNum: {
    label: "Street Number",
    required: true
  },
  streetName: {
    label: "Street Name",
    required: true
  },
  city: {
    label: "City",
    required: true
  },
  state: {
    label: "State",
    required: true,
    options: getOptionsFromEnumAndMap(Object.values(StateType), StateTypeLabel)
  },
  zip: {
    ...zipCodeRule,
    required: true
  }
};
