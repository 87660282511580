export const photoRequestInfoRules = {
  isCentralized: {
    label: "Centralized Photo Requests"
  },
  carbonCopyEmails: {
    label: "CC Emails"
  },
  resizePhotos: {
    label: "Resize Photos"
  },
  photoGuidelines: {
    label: "Photo Guidelines"
  },
  isCentralizedPhotoWarningEmail: {
    label: "Centralize Photo Warning Email"
  },
  backupPhone: {
    label: "Backup Phone"
  },
  autoFtp: {
    label: "Auto-FTP All Completed Photo Requests"
  },
  ftpUrl: {
    label: "FTP URL"
  },
  transferProtocol: {
    label: "Transfer Protocol"
  },
  ftpUsername: {
    label: "FTP Username"
  },
  ftpPassword: {
    label: "FTP Password"
  },
  photoManagerId: {
    label: "Photo Manager"
  }
};
