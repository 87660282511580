import { RecordSummary } from "@homesusa/record";
import { getCompanySummary } from "core/services/companies/company-saved-changes.service";
import React from "react";

export const useGetCompanySummary = (
  companyHistoryId: string
): { summary?: RecordSummary; getSummary: () => Promise<void> } => {
  const [summary, setSummary] = React.useState<RecordSummary>();

  const getSummary = React.useCallback(async (): Promise<void> => {
    setSummary(undefined);
    const data = await getCompanySummary()(companyHistoryId);
    setSummary(data);
  }, [companyHistoryId]);

  return { summary, getSummary };
};
