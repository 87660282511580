import { Input } from "@homesusa/form";
import Grid from "@mui/material/Grid";
import { XMLImportFormFieldsProps } from "core/interfaces/xml-import-form-fields-props.interface";
import React from "react";

export const XMLCorporationNumberFormFields = (
  props: XMLImportFormFieldsProps
): JSX.Element => {
  const [inputDisabled, setInputDisabled] = React.useState(false);

  const corporateBuilderNumberIsNotEmpty =
    props?.xmlCorporateBuilderNumber != null &&
    props?.xmlCorporateBuilderNumber?.trim().length !== 0;

  const corporateNumber = corporateBuilderNumberIsNotEmpty
    ? props?.xmlCorporateBuilderNumber
    : undefined;

  React.useEffect(() => {
    setInputDisabled(corporateBuilderNumberIsNotEmpty);
  }, [corporateNumber]);

  if (props.xmlCorporateBuilderNumber === undefined) {
    return <></>;
  }

  return (
    <Grid item xs={12}>
      <Input
        label="XML Corporation Number"
        name="xmlCorporateBuilderNumber"
        id="xmlCorporateBuilderNumber"
        defaultValue={corporateNumber}
        disabled={inputDisabled}
        maxLength={30}
        required
      />
    </Grid>
  );
};
