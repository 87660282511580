import React, { Fragment } from "react";

import { tableContainerStyle } from "@homesusa/core";
import { GridTable, GridProvider, Columns } from "@homesusa/grid-table";
import { AccordionBox, SummaryField, RowsViewer } from "@homesusa/record";
import { EmailLeads } from "core/interfaces";
import { EmailEntityTypeFormLabel, EmailPriorityFormLabel } from "core/enums";
import { CompanySavedChangesContext } from "modules/company-history/contexts";

type EmailLeadSummaryField = SummaryField<EmailLeads>;

function EmailLeadGrid({
  phones
}: {
  phones: EmailLeadSummaryField[];
}): JSX.Element {
  const columns = React.useMemo(() => {
    const columns: Columns<EmailLeadSummaryField> = [
      {
        Header: "Row",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1
      },
      {
        Header: "Email",
        accessor: (data: EmailLeadSummaryField): JSX.Element => (
          <RowsViewer
            item={data}
            displayValue={(item: EmailLeads): string => item.email}
          />
        )
      },
      {
        Header: "Type",
        accessor: (data: EmailLeadSummaryField): JSX.Element => (
          <RowsViewer
            item={data}
            displayValue={(item: EmailLeads): string =>
              EmailEntityTypeFormLabel.get(item.entityType) ?? item.entityType
            }
          />
        )
      },
      {
        Header: "Priority",
        accessor: (data: EmailLeadSummaryField): JSX.Element => (
          <RowsViewer
            item={data}
            displayValue={(item: EmailLeads): string =>
              EmailPriorityFormLabel.get(item.emailPriority) ??
              item.emailPriority
            }
          />
        )
      }
    ];
    return columns;
  }, []);

  return (
    <GridProvider
      pageSize={10}
      options={{ columns, data: phones, manualPagination: false }}
    >
      <GridTable />
    </GridProvider>
  );
}

export function EmailLeadSummary(): JSX.Element {
  const { summary } = React.useContext(CompanySavedChangesContext);
  const phones: EmailLeadSummaryField[] = React.useMemo(() => {
    const section = summary.find((item) => item?.name === "EmailLeads");
    return section ? Object.assign(section).fields : [];
  }, [summary]);

  if (phones.length === 0) {
    return <Fragment></Fragment>;
  }

  return (
    <AccordionBox title="EmailLeads" sx={tableContainerStyle}>
      <EmailLeadGrid phones={phones} />
    </AccordionBox>
  );
}
