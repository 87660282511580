import { CompanyDataForm as Company } from "core/interfaces";
import { EmailLeadActions } from "./email-lead-actions.type";

export const companyFormEmailLeadsReducer = (
  state: Company,
  action: EmailLeadActions
): Company => {
  switch (action.type) {
    case "AddEmailLead": {
      return {
        ...state,
        emailLeads: [...state.emailLeads, action.payload]
      };
    }
    case "RemoveEmailLead": {
      if (state.emailLeads.length < action.index) {
        return state;
      }
      state.emailLeads.splice(action.index, 1);
      return {
        ...state,
        emailLeads: [...state.emailLeads]
      };
    }
    case "UpdateEmailLead": {
      if (state.emailLeads.length < action.index) {
        return state;
      }
      state.emailLeads[action.index] = action.payload;
      return {
        ...state,
        emailLeads: [...state.emailLeads]
      };
    }
    default: {
      return state;
    }
  }
};
