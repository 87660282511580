import React from "react";

import { FormPanel } from "@homesusa/form";
import MlsEntityForm from "./mls-entity-form.component";
import MlsEntityToolbar from "./mls-entity-toolbar.component";
import { MlsEntityContext } from "../contexts";
import { MlsEntityFormProvider } from "../providers";
import { LinearProgress } from "@mui/material";

function MlsEntityFormPanel(): JSX.Element {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const { mlsEntity } = React.useContext(MlsEntityContext);

  if (!mlsEntity) {
    return <LinearProgress />;
  }

  return (
    <MlsEntityFormProvider initialData={mlsEntity}>
      <MlsEntityToolbar />
      <FormPanel
        tabs={["Information"]}
        selectedTab={selectedTab}
        onChangeTab={(value: number): void => setSelectedTab(value)}
      >
        <FormPanel.TabContent index={0} selected={selectedTab}>
          <MlsEntityForm />
        </FormPanel.TabContent>
      </FormPanel>
    </MlsEntityFormProvider>
  );
}

export default MlsEntityFormPanel;
