export enum EmailPriorityForm {
  One = "one",
  Two = "two",
  Three = "three",
  Four = "four",
  Five = "five",
  Six = "six"
}

export const EmailPriorityFormLabel = new Map<EmailPriorityForm, string>([
  [EmailPriorityForm.One, "One"],
  [EmailPriorityForm.Two, "Two"],
  [EmailPriorityForm.Three, "Three"],
  [EmailPriorityForm.Four, "Four"],
  [EmailPriorityForm.Five, "Five"],
  [EmailPriorityForm.Six, "Six"]
]);
