import React from "react";
import { Box, Grid, LinearProgress, Tooltip, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";

import {
  PhoneInput,
  Switch,
  TextArea,
  FormStyled,
  Select,
  Autocomplete
} from "@homesusa/form";
import { CompanyContext } from "modules/company/contexts";
import { useLocalOptions } from "modules/company/hooks";
import { AnimatedContainer, SectionBox } from "core/components";
import { classes } from "./media.styles";

function FormMediaTab(): JSX.Element {
  const { photoManagerEmployees, getPhotoManagerEmployees } =
    React.useContext(CompanyContext);
  const { resizeOptions } = useLocalOptions();

  const employeeOptions = React.useMemo(
    () =>
      photoManagerEmployees
        ? photoManagerEmployees.reduce(
            (a, employee) => ({
              ...a,
              [employee.userId]: `${employee?.firstName} ${employee.lastName}`
            }),
            {}
          )
        : {},
    [photoManagerEmployees]
  );

  React.useEffect(() => {
    if (photoManagerEmployees == null) {
      getPhotoManagerEmployees();
    }
  }, []);

  return (
    <AnimatedContainer>
      <FormStyled>
        <Typography sx={{ width: "100%", my: 3 }} variant="h2">
          Photo Request
        </Typography>
        <Box sx={classes.container}>
          <Box sx={classes.sections}>
            <SectionBox sx={classes.box} title="Photo Request">
              <Grid container>
                <Grid item xs={6}>
                  <Switch
                    label="Centralized Photo Requests"
                    name="photoRequestInfo.isCentralized"
                  />
                </Grid>
                <Grid item xs={6}>
                  {photoManagerEmployees ? (
                    <Autocomplete
                      label="Photo Manager"
                      options={employeeOptions}
                      name="photoRequestInfo.photoManagerId"
                      tooltip="Allow only the person populated in this field to submit or approve photo requests."
                    />
                  ) : (
                    <LinearProgress />
                  )}
                </Grid>

                <Grid item xs={5}>
                  <Switch
                    label="Centralize Photo Warning Email"
                    name="photoRequestInfo.isCentralizedPhotoWarningEmail"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title="Send ALL photo-related emails ONLY to the centralized Photo Manager(s).">
                    <Info color="disabled" />
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <PhoneInput
                    label="Backup Phone"
                    name="photoRequestInfo.backupPhone"
                    helperText="(Call if unable to make contact with sales counselor within 24 hours.)"
                  />
                </Grid>
              </Grid>
            </SectionBox>
            <SectionBox sx={classes.box} title="CC Emails">
              <Box sx={classes.section}>
                <TextArea
                  name="photoRequestInfo.carbonCopyEmails"
                  label="CC Emails"
                  rows={5}
                  maxLength={500}
                />
              </Box>
            </SectionBox>
          </Box>
          <Box sx={classes.sections}>
            <SectionBox sx={classes.box} title="Resize Photos">
              <Grid item xs={6}>
                <Select
                  label="Resize Photos"
                  helperText="Adding dimensions will cause a Download Resized Photos button to appear on the Photo Request form."
                  options={resizeOptions}
                  name="photoRequestInfo.resizePhotos"
                />
              </Grid>
            </SectionBox>
            <SectionBox sx={classes.box} title="Photo Guidelines">
              <Box sx={classes.section}>
                <TextArea
                  name="photoRequestInfo.photoGuidelines"
                  label="Photo Guidelines"
                  rows={5}
                  maxLength={1000}
                />
              </Box>
            </SectionBox>
          </Box>
        </Box>
      </FormStyled>
    </AnimatedContainer>
  );
}

export default FormMediaTab;
