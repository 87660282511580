import React from "react";
import { Grid } from "@mui/material";

import { AppContext } from "@homesusa/layout";
import { useRefresh, MarketCode } from "@homesusa/core";
import { FormContext } from "@homesusa/form";
import { MarketProspectingCredentials } from "core/interfaces";
import { useLocalOptions } from "modules/company/hooks";
import { ReverseProspectForm } from "modules/corporation/components/reverse-prospect-form.component";
import { CorporationFormContextProps } from "../contexts/corporation-form";
import { GridElementContext } from "modules/company/contexts";
import { ReverseProspectsGrid } from "./reverse-prospect-grid.component";

export function ReverseProspectSection(): JSX.Element {
  const { addAlert } = React.useContext(AppContext);
  const {
    formState: { marketProspectingCredentials },
    formDispatch
  } = React.useContext<CorporationFormContextProps>(FormContext);

  const [hideForm, refreshForm] = useRefresh();
  const [isEdit, setIsEdit] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState<number>();
  const [data, setData] = React.useState<MarketProspectingCredentials>();
  const { marketOptions } = useLocalOptions();
  const typesToShow: string[] = Object.values(MarketCode);
  const marketsFiltered = React.useMemo(() => {
    return marketProspectingCredentials.filter((rp) =>
      typesToShow.includes(rp.marketCode)
    );
  }, [marketProspectingCredentials]);

  const getIndex = (market: MarketProspectingCredentials): number => {
    return marketProspectingCredentials.findIndex(
      ({ userName, marketCode }) =>
        userName === market.userName && marketCode === market.marketCode
    );
  };

  const setEdit = ({ index }: { index: number }): void => {
    setData(marketsFiltered[index]);
    setEditIndex(getIndex(marketsFiltered[index]));
    setIsEdit(true);
    refreshForm();
  };

  const onSuccess = (): void => {
    setData(undefined);
    setEditIndex(undefined);
    setIsEdit(false);
    refreshForm();
  };

  const onSave = (marketState: MarketProspectingCredentials): void => {
    if (!formDispatch) {
      return;
    }
    if (isEdit && editIndex != undefined) {
      formDispatch({
        type: "UpdateReverseProspect",
        payload: marketState,
        index: editIndex
      });
    } else if (isUnique(marketState)) {
      formDispatch({
        type: "AddReverseProspect",
        payload: marketState
      });
    } else {
      addAlert({
        message: "Only one market per type is allowed",
        variant: "error"
      });
    }
  };

  const compareTypes = (
    a: MarketProspectingCredentials,
    b: MarketProspectingCredentials
  ): boolean => {
    return a.marketCode === b.marketCode;
  };

  const isUnique = (newMarket: MarketProspectingCredentials): boolean => {
    return !marketProspectingCredentials.some((market) =>
      compareTypes(market, newMarket)
    );
  };

  const onDelete = (index: number): void => {
    if (!formDispatch) {
      return;
    }
    formDispatch({
      type: "RemoveReverseProspect",
      index: getIndex(marketsFiltered[index])
    });
    if (editIndex === index) {
      onSuccess();
    }
  };

  return (
    <GridElementContext.Provider
      value={{
        isEdit,
        setEdit,
        onDelete
      }}
    >
      <Grid container columnSpacing={5}>
        <Grid item xs={5}>
          {!hideForm && (
            <ReverseProspectForm
              initialData={data}
              onSave={onSave}
              onSuccess={onSuccess}
              marketCodeTypes={marketOptions}
            />
          )}
        </Grid>
        <Grid item xs={7}>
          <ReverseProspectsGrid markets={marketsFiltered} />
        </Grid>
      </Grid>
    </GridElementContext.Provider>
  );
}
