import React, { Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { SettingsBackupRestore } from "@mui/icons-material";
import { useActivateCompany } from "modules/company/hooks";
import { CompanyContext } from "modules/company/contexts";

export function ActivateCompanyButton(): JSX.Element {
  const {
    company: { id }
  } = React.useContext(CompanyContext);
  const [showModal, setShowModal] = React.useState(false);
  const handleCloseModal = (): void => setShowModal(false);
  const handleShowModal = (): void => setShowModal(true);
  const onActivate = useActivateCompany();

  return (
    <Fragment>
      <Button onClick={handleShowModal} startIcon={<SettingsBackupRestore />}>
        Restore
      </Button>
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>Restore Company</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to restore this company?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(): void => {
              handleCloseModal();
              onActivate(id);
            }}
          >
            Restore
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
