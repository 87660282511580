import React from "react";
import {
  Typography,
  Grid,
  Box,
  Tooltip,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material";

import { RoleEmployee, useRefresh, CommissionType } from "@homesusa/core";
import {
  Select,
  Switch,
  Input,
  FormContext,
  PhoneInput,
  NumberInput
} from "@homesusa/form";
import { useHasRole } from "@homesusa/auth";

import { AnimatedContainer, SectionBox } from "core/components";
import { useLocalOptions } from "modules/company/hooks";
import { CompanyFormContextProps } from "modules/company/contexts";
import { Info } from "@mui/icons-material";
import { ContactPhoneSection } from "./phones/contact-phone-section.component";
import { ContactEmailsSection } from "./emails/contact-emails-section.component";

export const MlsComponent = (): JSX.Element => {
  const { formDispatch } =
    React.useContext<CompanyFormContextProps>(FormContext);
  const { hasEmployeeRole } = useHasRole();
  const [hideBuyerAgentCommission, refreshBuyerAgentCommission] = useRefresh();

  const changeCommissionType = (value: string): void => {
    if (formDispatch) {
      formDispatch({
        type: "FormChangeByInputName",
        inputName: "mlsInfo.buyerAgentCommissionType",
        payload: value
      });
      refreshBuyerAgentCommission();
    }
  };

  const rules = {
    commissionPercent: {
      maxLength: 4,
      label: "Buyers Agent Commission",
      regex: "^(8|[1-7]|[1-7][.][0-9])$",
      regexMessage:
        "Value or format is incorrect. Value must be within 1% to 8%",
      replaceRegex: "[a-zA-Z-]+",
      requiredToSubmit: true,
      numberFormat: {
        min: 1,
        thousandSeparator: false,
        prefix: "",
        decimalScale: 16,
        suffix: "%"
      }
    },
    commissionAmount: {
      maxLength: 7,
      regex: "",
      label: "Buyers Agent Commission",
      replaceRegex: "[a-zA-Z-]+",
      requiredToSubmit: true,
      regexMessage: "",
      numberFormat: {
        min: 1,
        thousandSeparator: true,
        prefix: "$",
        max: 50000,
        suffix: ""
      }
    }
  };
  const {
    formState: { mlsInfo, contactInfo, violationWarningEmail }
  } = React.useContext<CompanyFormContextProps>(FormContext);
  const { remarkOptions } = useLocalOptions();

  return (
    <AnimatedContainer>
      <Typography sx={{ width: "100%", my: 3 }} variant="h2">
        MLS Information
      </Typography>
      <Grid container columnGap={3} wrap="nowrap">
        <Grid item xs={5}>
          <SectionBox title="General">
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ "& .MuiFormControl-root": { width: "100%" } }}
              >
                <Select
                  label="Remark format for completed homes"
                  options={remarkOptions}
                  name="mlsInfo.remarksForCompletedHomes"
                  defaultValue={mlsInfo.remarksForCompletedHomes ?? "GEN"}
                />
              </Grid>
              <Grid item xs={12}>
                <Switch
                  label="Do NOT include MLS#, Builder, and Completion Date in Remarks"
                  name="mlsInfo.includeMls"
                  defaultValue={mlsInfo.includeMls}
                />
              </Grid>
              <Grid item xs={12}>
                <Switch
                  label="Block Square Footage Field"
                  name="mlsInfo.blockSquareFootage"
                  defaultValue={mlsInfo.blockSquareFootage}
                />
              </Grid>
            </Grid>
          </SectionBox>
        </Grid>

        <Grid item xs={5}>
          <SectionBox title="Centralize Buyer's Agent Commission Amount">
            <Grid container>
              <Grid item md={3}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  defaultValue={mlsInfo?.buyerAgentCommissionType}
                  onChange={(item): void => {
                    changeCommissionType(item.target.value);
                    if (formDispatch) {
                      formDispatch({
                        type: "FormChangeByInputName",
                        inputName: "mlsInfo.buyerAgentCommission",
                        payload: undefined
                      });
                    }
                  }}
                >
                  <FormControlLabel
                    value="percent"
                    control={<Radio />}
                    label="%"
                    checked={
                      mlsInfo?.buyerAgentCommissionType ===
                      CommissionType.Percent
                    }
                  />

                  <FormControlLabel
                    value="amount"
                    control={<Radio />}
                    label="$"
                    checked={
                      mlsInfo?.buyerAgentCommissionType ===
                      CommissionType.Amount
                    }
                  />
                </RadioGroup>
              </Grid>
              <Grid item md={6}>
                {!hideBuyerAgentCommission && (
                  <NumberInput
                    {...(mlsInfo?.buyerAgentCommissionType ==
                    CommissionType.Percent
                      ? rules.commissionPercent
                      : rules.commissionAmount)}
                    name="mlsInfo.buyerAgentCommission"
                    defaultValue={mlsInfo.buyerAgentCommission}
                  />
                )}
              </Grid>

              <Grid item>
                <Switch
                  label="Prevent access to BAC by Sales Employee"
                  name="mlsInfo.allowSalesConsuelorUpdateBuyerAgentCommission"
                  defaultValue={
                    mlsInfo.allowSalesConsuelorUpdateBuyerAgentCommission
                  }
                  disabled={!hasEmployeeRole([RoleEmployee.CompanyAdmin])}
                />
              </Grid>
              <Grid item xs={1}>
                <Tooltip title="Do not allow sales counselors/assistants to update the Buyer’s Agent Commission field on their listings and communities.">
                  <Info color="disabled" />
                </Tooltip>
              </Grid>
            </Grid>
          </SectionBox>
        </Grid>
      </Grid>
      <Box mt={5} />
      <ContactPhoneSection />

      <Box mt={5} />
      <Grid container columnSpacing={5}>
        <Grid item xs={5}>
          <SectionBox title="Violation Warning Emails">
            <Grid container>
              <Grid item xs={12}>
                <Input
                  label="Email"
                  helperText="The above person(s) will receive all violation warnings exclusively."
                  name="violationWarningEmail"
                  defaultValue={violationWarningEmail}
                  tooltip="You can add more than one Email by delimiting them with a semicolon ( ; )"
                  notAllowedWhiteSpaces="both"
                />
              </Grid>
            </Grid>
          </SectionBox>
        </Grid>
        <Grid item xs={5}>
          <SectionBox title="Email for Realtors to Contact You">
            <Grid container>
              <Grid item xs={12}>
                <Input
                  label="Email"
                  name="contactInfo.emailRealtorsContact"
                  defaultValue={contactInfo.emailRealtorsContact}
                  tooltip="You can add more than one Email by delimiting them with a semicolon ( ; )"
                  notAllowedWhiteSpaces="both"
                />
              </Grid>
            </Grid>
          </SectionBox>
        </Grid>
      </Grid>

      <Box mt={5} />
      <ContactEmailsSection />

      <Typography sx={{ width: "100%", my: 3 }} variant="h2">
        Centralized Company Phone Leads
      </Typography>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <SectionBox title="Phones">
            <Grid container>
              <Grid item xs={6}>
                <Switch
                  label="Centralize lead phone for sale"
                  name="phoneLeadInfo.isCentralizedForSale"
                />
              </Grid>
              <Grid item xs={6}>
                <PhoneInput
                  label="Centralize phone lead for sale"
                  name="phoneLeadInfo.centralizeLeadPhone"
                />
              </Grid>

              <Grid item xs={6}>
                <Switch
                  label="Centralize lead phone for lease"
                  name="phoneLeadInfo.isCentralizedForLease"
                />
              </Grid>
              <Grid item xs={6}>
                <PhoneInput
                  label="Centralize phone lead for lease"
                  name="phoneLeadInfo.centralizeLeadPhoneForLease"
                />
              </Grid>

              <Grid item xs={6}>
                <Switch
                  label="Centralize lead phone for lot"
                  name="phoneLeadInfo.isCentralizedForLot"
                />
              </Grid>
              <Grid item xs={6}>
                <PhoneInput
                  label="Centralize phone lead for lot"
                  name="phoneLeadInfo.centralizeLeadPhoneForLot"
                />
              </Grid>
            </Grid>
          </SectionBox>
        </Grid>
      </Grid>
    </AnimatedContainer>
  );
};
