export enum PhoneTypeForm {
  business = "business",
  other = "other",
  fax = "fax"
}

export const PhoneTypeFormLabel = new Map<PhoneTypeForm, string>([
  [PhoneTypeForm.business, "Business Phone"],
  [PhoneTypeForm.other, "Other Phone"],
  [PhoneTypeForm.fax, "Fax"]
]);
